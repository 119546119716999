import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import { CaretDown } from '../widgets/Icons'
import MagazineCarouselRow from './MagazineCarouselRow'
import MagazineArchiveHighlights from '../archive/MagazineArchiveHighlights'
import { Link } from '../general/util/Links'
import MagazineYearArchive from './MagazineYearArchive'
import TopRowAd from '../general/util/TopRowAd'
import MagazineDecadeLoader from './MagazineDecadeLoader'

import './MagazineArchive.scss'
import isEqual from 'lodash/isEqual'
import track from 'react-tracking'
import DigimagHero from '../navigation/DigimagHero'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import RandomArticle from '../widgets/RandomArticle'
import { pushPageDataToGTM } from '@otavamedia/om-component-library/lib/lib/utils'

const startDecade = 2000
const currentMonth = new Date().getMonth() + 1
const currentYear = new Date().getFullYear()
const currentDecade = Math.floor(currentYear / 10) * 10
const decades = []

for (let i = currentDecade; i >= startDecade; i -= 10) {
  decades.push(i)
}

const createYearList = decade => {
  const years = []

  for (let i = 9; i >= 0; --i) {
    if (decade + i < 2003 || decade + i > currentYear) {
      continue
    }
    years.push(decade + i)
  }

  // The first issue of a year is published in December of the previous year.
  // If we're in December 2018, we need to show issues for 2019 as well.
  if (years[0] === currentYear && currentMonth === 12) {
    years.unshift(currentYear + 1)
  }

  return years
}

/**
 * This displays the magazine archive (näköislehti) page (/arkisto).
 * Old magazine are displayed grouped by decade and year; this component creates a list of all years
 * we want to display.
 * It also fetches the latest archive magazine and displays a short TOC.
 * Rest of the page is created by other components.
 */
class MagazineArchive extends Component {
  constructor (props) {
    super(props)

    const yearsToList = createYearList(currentDecade)

    this.state = {
      selectedDecade: currentDecade,
      yearsToList,
      yearsToListLoaded: yearsToList.map(() => false),
      openMagazine: this.props.match ? this.props.match.params.number : null,
      highlightsLoaded: false,
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.match.params.number !== nextProps.match.params.number) {
      this.setState({
        openMagazine: nextProps.match.params.number
      })
    }
  }

  componentDidMount () {
    pushPageDataToGTM({})
    WP.getMagazines(`${currentYear - 1}-01-01`, `${currentYear}-12-31`, '', 1, true).then(([digimag]) => {
      WP.getArchivedMagazine(digimag.slug, true).then((digimag) => this.setState({ digimag }))
    })
  }

  componentWillUnmount () {
    this.props.setRendered(false)
  }

  setSelectedDecade = event => {
    const selected = Number(event.target.value)

    const yearsToList = createYearList(selected)

    this.setState({
      selectedDecade: selected,
      yearsToList,
      yearsToListLoaded: yearsToList.map(() => false),
    })
  }

  openMagazineModal = (magazine) => {
    if (!magazine.error) {
      this.props.setRendered(false)
      this.props.history.push(`/arkisto/${magazine.slug}`)
    }
  }

  closeMagazine = () => {
    this.props.setRendered(false)
    this.props.history.push('/arkisto/')
    this.props.setRendered(true)
  }

  onPagePartLoaded = () => {
    const { highlightsLoaded, yearsToListLoaded } = this.state
    if (highlightsLoaded && !yearsToListLoaded.includes(false)) {
      this.props.setRendered(true)
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(this.state, nextState)
  }

  render () {
    const { selectedDecade, yearsToList, digimag } = this.state
    /* eslint-disable jsx-a11y/no-onchange */
    return (
      <Fragment>
        <Helmet>
          <title>
            {'Arkisto'}
          </title>
        </Helmet>
        <TopRowAd display={true}/>
        {digimag ? <DigimagHero digimag={digimag} /> : null}
        <div styleName="archive-highlights">
          <div>
            <div styleName="archive-highlights-header">
              <h1>Arkiston aarteita</h1>
              <Link to={{ link: '/arkisto/aarteita' }}>Näytä lisää arkiston aarteita</Link>
            </div>
            <MagazineArchiveHighlights
              openMagazineModal={this.openMagazineModal}
              onLoad={() => {
                this.setState({ highlightsLoaded: true }, this.onPagePartLoaded)
              }}
            />
          </div>
        </div>
        <div styleName="main-content-col">
          <div>
            <div>
              <h1>Arkisto</h1>
              <p>Täältä löydät {window.om_constants.siteNameGen} näköislehdet vuodesta 2003 lähtien.</p>
            </div>
            <RandomArticle notWide={true}/>
          </div>
          <div styleName="year-row">
            <div styleName="decade-selection">
              <div styleName="decade-selection-title">Näytä vuosikymmen</div>
              <div styleName="select-div">
                <select id="decade-dropdown" onChange={this.setSelectedDecade} value={selectedDecade}>
                  {
                    decades.map((decade, idx) =>
                      <option styleName="decade-option" id={`decade-${decade}`} key={idx} value={`${decade}`}>{decade}</option>
                    )
                  }
                </select>
                <div styleName="select-caret"><CaretDown color="red" /></div>
              </div>
            </div>
          </div>
          {
            yearsToList.map((year, idx) => (
              <MagazineCarouselRow
                LoaderComponent={MagazineDecadeLoader}
                title={`${year}`}
                onMagazineItemClick={this.openMagazineModal}
                initialSlide={0}
                highlightSelected={false}
                key={`carousel-${year}`}
                year={year}
                onLoad={() => {
                  const { yearsToListLoaded } = this.state
                  yearsToListLoaded[idx] = true
                  this.setState({ yearsToListLoaded }, this.onPagePartLoaded)
                }}
              />
            ))
          }
          <div styleName="year-row">
            <div styleName="decade-selection">
              <div styleName="decade-selection-title">Näytä vuosikymmen</div>
              <div styleName="select-div">
                <select id="decade-dropdown" onChange={this.setSelectedDecade} value={selectedDecade}>
                  {
                    decades.map((decade, idx) =>
                      <option styleName="decade-option" id={`${decade}s`} key={idx} value={decade}>{decade}</option>
                    )
                  }
                </select>
                <div styleName="select-caret"><CaretDown color="red" /></div>
              </div>
            </div>
          </div>
        </div>
        {this.state.openMagazine
          ? <MagazineYearArchive number={this.state.openMagazine} closeModal={this.closeMagazine} setRendered={this.props.setRendered}/>
          : null}
      </Fragment>
    )
    /* eslint-enable jsx-a11y/no-onchange */
  }
}

MagazineArchive.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  setRendered: PropTypes.func,
}

export default track({ gtmContext: ['MagazineArchive'] })(withErrorBoundary(
  MagazineArchive,
  ErrorPlaceholder(null)
))
