import React, { Component } from 'react'

import { Link } from '../general/util/Links'
import Image from '../general/util/Image'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import { logException } from '@otavamedia/om-component-library/lib/lib/errors'

import './LatestMagazine.scss'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import PropTypes from 'prop-types'

export default class LatestMagazine extends Component {
  static propTypes = {
    className: PropTypes.string,
    doneLoading: PropTypes.func,
  }

  _mounted = false
  state = {
    magazine: null,
  }

  async componentDidMount () {
    this._mounted = true
    try {
      const magazine = await WP.getLatestMagazine()

      this.asyncSetState({ magazine }, () => this.props.doneLoading && this.props.doneLoading())
    } catch (e) {
      this.props.doneLoading && this.props.doneLoading()
      logException(e)
    }
  }

  asyncSetState (...args) {
    // avoid setting state after unmount in case async operations attempts to do so
    if (this._mounted) this.setState(...args)
  }

  componentWillUnmount () {
    this._mounted = false
  }

  render () {
    const { magazine } = this.state
    const { className } = this.props
    if (!magazine) {
      return null
    }

    const { coverImage } = magazine

    return (
      <article styleName={(className || '') + ' tvtm-container'}>
        <Link to={{ link: '/lehti/' + magazine.slug }}>
          <span styleName="row"><h4 styleName="magazine-number"><span styleName="quot">&#187;</span> Lue {magazine.name}</h4></span>
          <Image data={coverImage} alt={`Lehden ${magazine.name} kansi`} size={IMAGE_SIZE.MEDIUM} sizes={'230px'}/>
        </Link>
      </article>
    )
  }
}
