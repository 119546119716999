import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'

import magazineStore from '@otavamedia/om-component-library/lib/kea/weeklyMagazine'
import application from '@otavamedia/om-component-library/lib/kea/application'
import { Link } from '../general/util/Links'
import { LibraryIconRed, ListIconRed, PreviousArrow, NextArrow, InfoIcon } from '../widgets/Icons'
import ToCModal from './TableOfContentsModal'
import { HEADER_STATE } from '../views/Header'

import './MagazineMenu.scss'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import track from 'react-tracking'
import { InfoHighlight, InfoText } from '../widgets/InfoHighlight'
import DigimagHelp from '../../containers/DigimagHelp'

@track({ gtmContext: ['MagazineMenu'] })
@connect({
  props: [
    magazineStore, [
      'contents as digimag',
      'isLoading',
      'previousArticle',
      'nextArticle',
      'currentArticleNumber',
      'articleCount',
    ],
    application, [
      'isMagazineView',
      'isMagazineArticleView',
      'isAdMagazine',
      'isThemeMagazine',
    ],
  ],
  actions: [
    magazineStore, [
      'loadMagazine',
    ],
  ]
})
class MagazineMenu extends Component {
  static propTypes = {
    digimag: PropTypes.object,
    previousArticle: PropTypes.object,
    nextArticle: PropTypes.object,
    isMagazineArticleView: PropTypes.bool,
    isMagazineView: PropTypes.bool,
    isLoading: PropTypes.bool,
    articleCount: PropTypes.number,
    currentArticleNumber: PropTypes.number,
    history: PropTypes.object,
    isAdMagazine: PropTypes.bool,
    isThemeMagazine: PropTypes.bool,
    headerState: PropTypes.string,
    pathname: PropTypes.string,
    demo: PropTypes.bool,
    highlight: PropTypes.string,
    loadMagazine: PropTypes.func,
    actions: PropTypes.object,
  }

  constructor (props) {
    super(props)
    this.state = {
      tocOpen: false,
      showHelp: false,
      highlight: props.highlight ? parseInt(props.highlight) : null
    }
    if (!props.demo) {
      props.actions.loadMagazine(props.pathname)
    }
  }

  toggleToc = () => {
    this.setState({
      tocOpen: !this.state.tocOpen
    })
  }

  componentWillReceiveProps (newProps) {
    if (newProps.currentArticleNumber != null && this.props.currentArticleNumber != null &&
      newProps.currentArticleNumber !== this.props.currentArticleNumber) {
      this.setState({ tocOpen: false })
    }
    if (newProps.pathname !== this.props.pathname && !newProps.demo) {
      newProps.actions.loadMagazine(newProps.pathname)
    }
  }

  gotoDigiArchive = () => {
    if (this.props.pathname.includes('/teemalehti')) {
      this.props.history.push('/teemalehdet/')
    } else {
      this.props.history.push('/digilehdet/')
    }
  }

  render () {
    const { tocOpen, showHelp } = this.state
    const {
      digimag,
      isLoading,
      isMagazineArticleView,
      isMagazineView,
      isAdMagazine,
      isThemeMagazine,
      headerState,
      previousArticle,
      nextArticle,
      demo,
    } = this.props
    const { highlight } = this.state
    const prevExists = Boolean(previousArticle && previousArticle.link)
    const nextExists = Boolean(nextArticle && nextArticle.link)
    const magazineExists = Boolean(digimag && Object.keys(digimag).length && digimag.name)
    const submenuContainerStyles = classnames('magazine-menu', {
      collapsed: headerState === HEADER_STATE.COLLAPSED,
    })

    return (
      <Fragment>
        {showHelp ? <DigimagHelp close={() => this.setState({ showHelp: false })} /> : null}
        {(demo || (magazineExists && !tocOpen)) &&
        <nav styleName={submenuContainerStyles}>
          <div styleName="container">
            {isAdMagazine
              ? (
                <button styleName="back-to-archive-button border-left menu-button">
                </button>
              )
              : (
                <button styleName={demo && !(highlight === 1)
                  ? 'back-to-archive-button border-left menu-button disabled'
                  : 'back-to-archive-button border-left menu-button'} onClick={!demo ? this.gotoDigiArchive : undefined}>
                  <LibraryIconRed/>
                  {(highlight === 1) && <InfoHighlight/>}
                </button>
              )}

            <button
              styleName={demo && !(highlight === 2)
                ? 'toc-button border-left menu-button disabled'
                : 'toc-button border-left menu-button'}
              onClick={!demo ? this.toggleToc : undefined}>
              <div styleName="text">
                <ListIconRed/>
                <span styleName="title2">{digimag.name}</span>
                <span styleName="title">&nbsp;-&nbsp;Sisällysluettelo</span>
              </div>
              {(highlight === 2) && <InfoHighlight/>}
            </button>

            {(demo || isMagazineArticleView)
              ? (
                <div styleName="article-links">
                  <button onClick={demo ? undefined : () => this.setState({ showHelp: true })}
                    styleName={demo && !(highlight === 3) ? 'border-left menu-button disabled' : 'border-left menu-button'}>
                    <span styleName="info">
                      <InfoIcon/>
                      <span styleName="title">Digilehden lukuohje</span>

                    </span>
                    {(highlight === 3) && <InfoHighlight/>}
                  </button>

                  {demo || prevExists
                    ? (
                      <Link to={{ link: demo ? '/' : previousArticle.link }} demo={demo}
                        styleName={demo && !(highlight === 4)
                          ? 'previous border-left menu-button disabled'
                          : 'previous border-left menu-button'}>
                        <PreviousArrow/>
                        <div styleName="article">
                          <span styleName="title">Edellinen artikkeli</span>
                        </div>
                        {(highlight === 4) && <InfoHighlight/>}
                      </Link>
                    )
                    : (
                      <div styleName="previous border-left menu-button disabled">
                        <PreviousArrow/>
                      </div>
                    )}

                  {demo || nextExists
                    ? (
                      <Link to={{ link: demo ? '/' : nextArticle.link }} demo={demo}
                        styleName={demo && !(highlight === 4)
                          ? 'next border-left border-right menu-button disabled'
                          : 'previous border-left border-right menu-button'}>
                        <div styleName="article">
                          <span styleName="title">Seuraava artikkeli</span>
                        </div>
                        <NextArrow/>
                      </Link>
                    )
                    : (
                      <div styleName="next border-left menu-button disabled border-right">
                        <NextArrow/>
                      </div>
                    )}
                </div>
              )
              : isMagazineView
                ? (
                  <div styleName="article-links">
                    <button onClick={() => this.setState({ showHelp: true })}
                      styleName={demo && !(highlight === 3) ? 'border-left menu-button disabled' : 'border-left menu-button'}>
                      <span styleName="info">
                        <InfoIcon/>
                        <span styleName="title">Digilehden lukuohje</span>

                      </span>
                      {(highlight === 3) && <InfoHighlight/>}
                    </button>
                    <div styleName="previous border-left menu-button disabled">
                      <PreviousArrow/>
                    </div>
                    {nextExists
                      ? <Link to={{ link: nextArticle.link }} styleName="next menu-button border-left border-right">
                        <div styleName="article">
                          <span styleName="title">Seuraava artikkeli</span>
                        </div>

                        <NextArrow/>
                      </Link>
                      : <div styleName="next border-left menu-button disabled border-right">
                        <NextArrow/>
                      </div>}
                  </div>
                )
                : (
                  <div styleName="article-links">

                  </div>
                )}
          </div>
        </nav>
        }
        {(!isLoading && magazineExists) && (
          <ToCModal open={tocOpen} toggleModal={this.toggleToc} digimag={digimag} showTM={!isThemeMagazine} compact={false} />
        )}
        {demo ? <InfoText highlight={highlight}/> : null}
      </Fragment>
    )
  }
}

export default withRouter(MagazineMenu)
