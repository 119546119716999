import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ErrorPlaceholder, withErrorBoundary } from '../../components/general/util/ErrorBoundaries'
import track from 'react-tracking'
import { Link } from '../general/util/Links'
import bestLogo from '../../assets/logos/TM_ovaalilogot_parhaat.png'
import { ShowAll } from './ShowAll'

import './BestProducts.scss'

@track({ gtmContext: ['BestProducts'] })
class BestProducts extends Component {
  static propTypes = {
    isBest: PropTypes.bool,
  }

  constructor (props) {
    super()
    if (props.isBest) {
      this.state = {
        categories: {
          'Autot, moottoripyörät, polkupyörät, renkaat ja lisävarusteet': {
            url: '/testivoittajat/autot/',
            image: 'https://cdn.tekniikanmaailma.fi/wp-content/uploads/s3/2019/08/12135058/mclaren_senna_31-e1563823926229-300x200.jpg'
          },
          'Kodinkoneet, älylaitteet, työkalut ja rakentaminen': {
            url: '/testivoittajat/teknologia/',
            image: 'https://cdn.tekniikanmaailma.fi/wp-content/uploads/s3/2019/09/23095550/usrsuit2-300x156.jpg'
          },
          'Kuulokkeet, kaiuttimet ja kaiutinjärjestelmät': {
            url: '/testivoittajat/audio/',
            image: 'https://cdn.tekniikanmaailma.fi/wp-content/uploads/s3/2019/07/12162034/kuulokkeet_041213322__33-300x200.jpg'
          },
          'Kamerat, lämpökamerat ja autokamerat': {
            url: '/testivoittajat/kamerat/',
            image: 'https://cdn.tekniikanmaailma.fi/wp-content/uploads/s3/2019/07/01125052/transcend-driivepro-230-300x200.jpg'
          },
          'Puhelimet, rannetietokoneet ja älylaitteet': {
            url: '/testivoittajat/mobiililaitteet/',
            image: 'https://cdn.tekniikanmaailma.fi/wp-content/uploads/s3/2019/08/28123749/tm_kovankaytonkannykat_028-300x200.png'
          },
          Televisiot: {
            url: '/testivoittajat/televisiot/',
            image: 'https://cdn.tekniikanmaailma.fi/wp-content/uploads/s3/2019/06/12134143/loppu_telkkarit_03-300x200.png'
          }
        },
      }
    } else {
      this.state = {
        categories: {
          'Autot, moottoripyörät, polkupyörät, renkaat ja lisävarusteet': {
            url: '/testit-autot/',
            image: 'https://cdn.tekniikanmaailma.fi/wp-content/uploads/s3/2019/08/12135058/mclaren_senna_31-e1563823926229-300x200.jpg'
          },
          'Kodinkoneet, älylaitteet, työkalut ja rakentaminen': {
            url: '/testit-elektroniikka/kodintekniikka',
            image: 'https://cdn.tekniikanmaailma.fi/wp-content/uploads/s3/2019/09/23095550/usrsuit2-300x156.jpg'
          },
          'Kuulokkeet, kaiuttimet ja kaiutinjärjestelmät': {
            url: '/testit-elektroniikka/audio',
            image: 'https://cdn.tekniikanmaailma.fi/wp-content/uploads/s3/2019/07/12162034/kuulokkeet_041213322__33-300x200.jpg'
          },
          'Kamerat, lämpökamerat ja autokamerat': {
            url: '/testit-elektroniikka/kamerat',
            image: 'https://cdn.tekniikanmaailma.fi/wp-content/uploads/s3/2019/07/01125052/transcend-driivepro-230-300x200.jpg'
          },
          'Puhelimet, rannetietokoneet ja älylaitteet': {
            url: '/testit-elektroniikka/mobiililaitteet',
            image: 'https://cdn.tekniikanmaailma.fi/wp-content/uploads/s3/2019/08/28123749/tm_kovankaytonkannykat_028-300x200.png'
          },
          Televisiot: {
            url: '/testit-elektroniikka/televisiot',
            image: 'https://cdn.tekniikanmaailma.fi/wp-content/uploads/s3/2019/06/12134143/loppu_telkkarit_03-300x200.png'
          }
        },
      }
    }
  }

  render () {
    const { categories } = this.state
    const { isBest } = this.props
    const categoryTitles = Object.keys(categories)

    return <div styleName="best-products">
      <div styleName="header">
        <img src={bestLogo} alt=""/>
        <h2>{isBest ? 'Katso lisää Suomen parhaita tuotteita' : `Katso lisää ${window.om_constants.siteNameGen} testejä, koeajoja ja vertailuja`}</h2>
        {isBest ? <ShowAll url="/testivoittajat/" inline/> : null}
      </div>
      {categoryTitles.map((cat, idx) => (
        <div key={idx} styleName="full-row">
          <Link to={{ link: categories[cat].url }}><img src={categories[cat].image} alt=""/></Link>
          <Link to={{ link: categories[cat].url }}>{cat}</Link>
        </div>
      ))}
    </div>
  }
}

export default withErrorBoundary(
  BestProducts,
  ErrorPlaceholder()
)
