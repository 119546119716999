import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { connect } from 'kea'
import ReactPlaceholder from 'react-placeholder'

import querystring from 'querystring'
import appLogic from '@otavamedia/om-component-library/lib/kea/application'
import Resolver from './Resolver'
import IndexHighlights from '../components/views/IndexHighlights'
import Sidebar from '../components/views/Sidebar'
import NewsfeedLoader from '../components/general/util/NewsfeedLoader'
import PostList from '../components/general/lists/PostList'
import ArticleListItem from '../components/general/article/ArticleListItem'
import Breakpoint from '@otavamedia/om-component-library/lib/util/Breakpoint'
import track from 'react-tracking'
import TopRowAd from '../components/general/util/TopRowAd'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import get from 'lodash/get'
import some from 'lodash/some'
import IndexSidebar from '../components/views/IndexSidebar'
import GAMNativeAdHolder from '../components/widgets/GAMNativeAdHolder'
import TopPicks from '../components/widgets/TopPicks'
import LatestPosts from '../components/general/popular/LatestPosts'
import { indexLayout as layout } from '../lib/defaults'
import { pushPageDataToGTM } from '@otavamedia/om-component-library/lib/lib/utils'
import RandomArticle from '../components/widgets/RandomArticle'
import {
  AdsForDesktop,
  AdsForMobile,
  AdSlotDesk2,
  AdSlotMob2,
} from '../components/general/ads/Ads'

import './FrontPage.scss'

export default @track({ gtmContext: ['FrontPage'] })
/**
 * This renders the front page of the site.
 * It loads the highlights and editors' picks itself, the rest of the page is created by lots of other
 * components.
 */
@connect({
  props: [
    appLogic, [
      'settings',
    ],
  ]
})
class FrontPage extends Component {
  loaded = {
    sidebar: false,
    highlights: false,
  }

  constructor (props) {
    super(props)
    this.state = {
      ready: false,
      error: false,
      highlights: [],
      liveVideo: {},
      stickies: [],
      exclude: [],
    }
  }

  static propTypes = {
    settings: PropTypes.object,
    match: PropTypes.object,
    setRendered: PropTypes.func.isRequired,
    history: PropTypes.object,
    location: PropTypes.object,
  }

  componentWillReceiveProps (newProps) {
    this.setState({ page: parseInt(get(newProps, 'match.params.page')) || 1 })
  }

  async componentDidMount () {
    pushPageDataToGTM({ type: 'index' })
    try {
      const [{ highlights, stickies }, liveVideo, editorsPicks, shortcuts] = await Promise.all([
        WP.getFrontpage(),
        WP.getLiveVideo(),
        WP.getEditorsPicks(),
        WP.getShortcuts(),
      ])
      const exclude = highlights
        .map(x => x.item.id)
        .concat(stickies.map(x => x.id))

      // Is this block even needed?
      if (stickies.length < layout.highlights.stickyCount) {
        const offset = layout.highlights.stickyCount - stickies.length
        const { data: fill } = await WP.getForContext(WP.postTypes.post, { per_page: offset, exclude })

        fill.forEach(post => {
          stickies.concat(post)
          exclude.concat(post.id)
        })
      }

      this.setState({
        ready: true,
        highlights: highlights.concat(editorsPicks),
        stickies,
        exclude,
        liveVideo,
        shortcuts,
        error: false,
      })
      this.doneLoading('highlights')
    } catch (e) {
      this.setState({ error: e })
    }
  }

  componentWillUnmount () {
    this.props.setRendered(false)
  }

  doneLoading (element) {
    if (this.loaded[element]) {
      return
    }
    this.loaded[element] = true
    if (!some(Object.values(this.loaded), (x) => !x)) {
      this.props.setRendered(true)
    }
  }

  render () {
    const { location, history } = this.props
    const { ready, highlights, stickies, exclude, error, liveVideo, shortcuts } = this.state
    const postListStickies = stickies ? stickies.slice(layout.highlights.stickyCount) : []

    if (location.search) {
      const qs = querystring.parse(location.search.substring(1))

      if (qs.p && qs.preview) {
        return <Resolver {...this.props} />
      }
    }

    return (
      <Fragment>
        <Helmet>
          <title>
            {window.om_constants.siteName}
          </title>
          <meta name="http:status" content="200" />
          <meta name="description" content="TM Rakennusmaailma neuvoo ja opastaa remontoinnissa ja rakentamisessa. Tilaajana saat myös testit ja vertailut sekä näköislehden arkistoineen."/>
          <meta property="og:description" content="TM Rakennusmaailma neuvoo ja opastaa remontoinnissa ja rakentamisessa. Tilaajana saat myös testit ja vertailut sekä näköislehden arkistoineen."/>
        </Helmet>
        <TopPicks />
        <TopRowAd isArticleView={false} display={true}/>
        <div styleName="main-content-col-wide">
          <div styleName="sub-container">
            <div styleName="sub-row">
              <h2 className="screen-reader-text">Pääjutut</h2>
              <div id="service-worker-test">

              </div>
              <IndexHighlights error={error} highlights={highlights} liveVideo={liveVideo} stickies={stickies}/>
            </div>
          </div>
        </div>
        <aside styleName="sidebar-col-narrow">
          <Sidebar popular latestMag latestMagazine doneLoading={() => this.doneLoading('sidebar')}/>
        </aside>
        <Breakpoint maxBreakpoint={767}>
          <aside styleName="sidebar-col">
            <IndexSidebar doneLoading={() => this.doneLoading('indexSidebar')} shortcuts={shortcuts}/>
            <RandomArticle/>
          </aside>
        </Breakpoint>
        <div styleName="divider"/>
        <div styleName="full-width-col">
          <div className='row'>
            <div styleName="full-width-col">
              <Fragment>
                <AdsForDesktop>
                  <AdSlotDesk2 />
                </AdsForDesktop>
                <AdsForMobile>
                  <AdSlotMob2 />
                </AdsForMobile>
              </Fragment>
            </div>
          </div>
        </div>
        <div styleName="main-content-col">
          <div styleName="sub-container">
            <div styleName="native-ads">
              <GAMNativeAdHolder grow={true} />
            </div>
                          <LatestPosts compact/>
                        <Breakpoint minBreakpoint={767}>
              <div styleName="native-ads">
                <GAMNativeAdHolder grow={true} type={2}/>
              </div>
            </Breakpoint>
                          <ReactPlaceholder ready={ready || !!error} customPlaceholder={<NewsfeedLoader/>}>
                <div styleName="sub-row newsfeed">
                  <main styleName="full-width-col">
                    <PostList
                      context={WP.postTypes.post}
                      layout={layout.newsFeed}
                      stickies={postListStickies}
                      ListItem={ArticleListItem}
                      onLoad={() => this.doneLoading('postlist')}
                      blacklist={exclude}
                      disablePagination={true}
                      history={history}
                      error={error}/>
                  </main>
                </div>
              </ReactPlaceholder>
                      </div>
        </div>
                  <Breakpoint minBreakpoint={768}>
            <aside styleName="sidebar-col">
              <IndexSidebar doneLoading={() => this.doneLoading('indexSidebar')} shortcuts={shortcuts} blocks={{ lateShortcuts: true }}/>
              <RandomArticle/>
            </aside>
          </Breakpoint>
              </Fragment>
    )
  }
}
