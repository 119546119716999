/* global googletag */
import { EventEmitter } from 'events'
import * as AdUtils from './AdUtils'
// import { runOptimizer } from './IASOptimization'
import debounce from 'lodash/debounce'
import { logException } from '@otavamedia/om-component-library/lib/lib/errors'
import axios from 'axios'

export const debugAdsMode = () => window.location.search.includes('debugads')

class Manager {
  debug = false // set true to debug
  events
  initialized
  adformInitialized
  googleGPTScriptLoadPromise = null
  slotsToRefresh = {}

  constructor () {
    this.events = new EventEmitter().setMaxListeners(0)
  }

  getGoogletag () {
    if (this.googleGPTScriptLoadPromise === null) {
      this.googleGPTScriptLoadPromise = AdUtils.loadGPTScript()
    }
    return this.googleGPTScriptLoadPromise
  }

  loadGPT () {
    return this.getGoogletag()
  }

  init () {
    if (this.initialized) {
      console.warn('AdManager already initialized.')
      return
    }

    this.log('Init')

    this.initialized = true

    googletag.cmd.push(() => {
      const pubadsService = googletag.pubads()

      pubadsService.setTargeting('url_domain', window.location.host)
      this.updateTargeting()

      // Power
      // googletag.pubads().definePassback('/135812570/tracking/impression-tracking', [1, 1]).setTargeting('impression-campaign', ['powertmpilottikiintea']).display()

      const m = document.cookie.match(/frosmo_keywords=.([^;$]+)/)
      if (m && m.length) {
        pubadsService.setTargeting('frosmo', m[1].split('.'))
      }

      // this.initLeikiScript()
      pubadsService.disableInitialLoad()
      pubadsService.enableAsyncRendering()
      pubadsService.collapseEmptyDivs(true)
      googletag.enableServices()
      pubadsService.enableLazyLoad({
        fetchMarginPercent: 60,
        renderMarginPercent: 30,
        mobileScaling: 1
      })

      pubadsService.addEventListener('slotRenderEnded', (event) => {
        const slotId = event.slot.getSlotElementId()
        this.events.emit('slotRenderEnded', { slotId, event })
      })
      pubadsService.addEventListener('impressionViewable', (event) => {
        const slotId = event.slot.getSlotElementId()
        this.events.emit('impressionViewable', { slotId, event })
      })
    })
  }

  initLeikiScript () {
    /*
    window._leikiw = window._leikiw || []
    window._leikiw.push({
      server: '//kiwi66.leiki.com/focus',
      isSegments: true,
      segmentCallback: function (data) {
        data.segments.contextual && googletag.pubads().setTargeting('leiki_contextual', data.segments.contextual.join())
        data.segments.personal && googletag.pubads().setTargeting('leiki_personal', data.segments.personal.join())
      }
    })
     */
    let t = new Date().getTime(); t -= t % (1000 * 60 * 60 * 24 * 30)
    const l = document.createElement('script'); l.type = 'text/javascript'; l.async = true
    l.src = '//kiwi66.leiki.com/focus/widgets/loader/loader-min.js?t=' + t
    const s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(l, s)
  }

  initAdform () {
    if (this.adformInitialized) return
    this.adformInitialized = true

    const addScript = document.createElement('script')
    addScript.setAttribute('src', '//track.adform.net/serving/scripts/trackpoint/')
    document.head.appendChild(addScript)
  }

  adformPowerArticleAdClick () {
    axios.get('https://track.adform.net/C/?bn=26763857')
  }

  adformDNAArticleAdClick () {
    axios.get('https://track.adform.net/C/?bn=27466383')
  }

  adformPush (data) {
    window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : [])
    window._adftrack.push(data)

    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.src = 'https://track.adform.net/serving/scripts/trackpoint/async/'
    const x = document.getElementsByTagName('script')[0]
    x.parentNode.insertBefore(s, x)
  }

  attachSlotRenderEnded (callback) {
    this.events.on('slotRenderEnded', callback)
  }

  detachSlotRenderEnded (callback) {
    this.events.removeListener('slotRenderEnded', callback)
  }

  attachSlotIsViewable (callback) {
    this.events.on('impressionViewable', callback)
  }

  attachSlotResponseReceived (callback) {
    this.events.on('slotResponseReceived', callback)
  }

  detachSlotIsViewable (callback) {
    this.events.removeListener('impressionViewable', callback)
  }

  updateTargeting () {
    if (!this.initialized) return

    googletag.cmd.push(() => {
      googletag.pubads().setTargeting('url_path', window.location.pathname)
      googletag.pubads().setTargeting('url_params', window.location.search.substring(1))
    })
  }

  addSlotToRefreshQueue (slot) {
    this.slotsToRefresh[slot.getSlotId().getId()] = slot
    this.waitAndRefreshQueue()
  }

  /**
     * googletag.pubads().refresh() has significant memory leak, so we don't want to call it too often.
   * This is why we need to debounce the calls so we can handle multiple ad slots
   * within single refresh() call. The user will still run out of memory eventually,
   * but this makes the situation 10x better in case there are 10 ads in a page that could be
   * refreshed at once.
   */
  waitAndRefreshQueue = debounce(() => {
    this.refreshQueue()
  }, 500)

  refreshQueue () {
    const _this = this
    googletag.cmd.push(() => {
      if (Object.values(_this.slotsToRefresh).length) {
        const nativeAdSlots = Object.values(this.slotsToRefresh).filter(x => x.getAdUnitPath().includes('native-card'))
        // console.log('Refreshing slots', Object.values(this.slotsToRefresh).map(x => x.getAdUnitPath()))
        try {
          Object.values(this.slotsToRefresh).forEach((slot) => {
            // console.log('GAMDebug: Calling googletag.display(slot) for ', slot.getSlotId().getId())
            googletag.display(slot)
          })
          if (nativeAdSlots && nativeAdSlots.length) {
            window.googletag.pubads().refresh(nativeAdSlots)
          }
          // window.googletag.pubads().refresh(Object.values(this.slotsToRefresh))
          // runOptimizer(Object.values(this.slotsToRefresh))
        } catch (e) {
          logException(e)
        }
        _this.slotsToRefresh = {} // clear
      } else if (Object.values(_this.slotsToRefresh).length) {
        // we have slots to refresh, but no consent yet, try again in a while
        setTimeout(_this.refreshQueue.bind(_this), 1000)
      }
    })
  }

  log (...args) {
    if (this.debug) {
      const logType = 'log'
      console[logType]('AdManager - ', ...args)
    }
  }
}

const instance = new Manager()
export default instance
