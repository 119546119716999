import React, { Component } from 'react'
import PropTypes from 'prop-types'
import HTMLParser from '@otavamedia/om-component-library/lib/lib/html-parser'
import { STATUS } from '@otavamedia/om-component-library/lib/lib/request-state'
import MagazineHighlight from '../weeklyMagazine/MagazineHighlight'
import { Link } from '../general/util/Links'
import MagazineArchiveHighlightsLoader from './MagazineArchiveHighlightsLoader'
import ArchiveHighlightsLoader from '../general/util/ArchiveHighlightsLoaders'
import './MagazineArchiveHighlights.scss'
import track from 'react-tracking'

class MagazineArchiveHighlights extends Component {
  static propTypes = {
    onLoad: PropTypes.func,
    openMagazineModal: PropTypes.func,
  }

  openMagazine = magazine => event => {
    this.props.openMagazineModal(magazine)
  }

  render () {
    return (
      <MagazineArchiveHighlightsLoader onLoad={this.props.onLoad} render={(state) => {
        const {
          magazines,
          // specialNumberIndex,
          status,
        } = state

        let treasure
        let retroTest
        // let specialNumber

        if (status === STATUS.DONE) {
          treasure = magazines.treasures[0]
          retroTest = magazines.retroTests[0]
          // specialNumber = magazines.specialNumbers[specialNumberIndex]
        }

        return status === STATUS.DONE
          ? (
            <div styleName="highlights-container">
              {!!treasure && (
                <button styleName="highlight-container" onClick={this.openMagazine(treasure)}>
                  <MagazineHighlight
                    {...treasure}
                    htmlParser={HTMLParser.render}
                    LinkComponent={Link}
                    header="Arkiston aarre"
                  />
                </button>
              )}
              {!!retroTest && (
                <button styleName="highlight-container" onClick={this.openMagazine(retroTest)}>
                  <MagazineHighlight
                    {...retroTest}
                    htmlParser={HTMLParser.render}
                    LinkComponent={Link}
                    header={window.om_constants.archiveHighlights}
                  />
                </button>
              )}
              {/* !!specialNumber && (
                <button styleName="highlight-container" onClick={this.openMagazine(specialNumber)}>
                  <MagazineHighlight
                    {...specialNumber}
                    htmlParser={HTMLParser.render}
                    LinkComponent={Link}
                    header="Erikoisnumero"
                  />
                </button>
              ) */}
            </div>
          )
          : (
            <ArchiveHighlightsLoader />
          )
      }}/>
    )
  }
}

export default track({ gtmContext: ['MagazineArchiveHighlights'] })(MagazineArchiveHighlights)
