import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'

import ProductComment from '../comments/ProductComment'
import galleryLogic from '@otavamedia/om-component-library/lib/kea/gallery'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import CustomForm from '../comments/CustomForm'

import './ProductComments.scss'

@connect({
  actions: [
    galleryLogic, [
      'openGallery',
    ],
    auth, [
      'setCommenting'
    ]
  ],
  props: [
    auth, [
      'loggedIn',
      'username'
    ]
  ]
})
  /**
   * Displays comments and a commenting box for a ProductCard. THe functionality could probably be merged into
   * ProductCard as this isn't used anywhere else.
   */
class ProductComments extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    loggedIn: PropTypes.bool,
    isMemory: PropTypes.bool,
    username: PropTypes.string,
    actions: PropTypes.object,
  }

  state = {
    open: false,
    okToSend: false,
    grade: 3,
    isWriting: false,
    selectedFile: null,
    comment: '',
    comments: [],
    readerGrade: 0,
    waitingApproval: false,
    page: 0
  }

  showComments (open) {
    this.setState({ open })
  }

  addComment () {
    this.setState({ isWriting: true })
  }

  async loadComments (props) {
    let { data: comments } = await WP.getComments(this.props.id)
    comments
      .forEach((comment, idx) => {
        if (comment.parent) {
          const parent = comments.find(c => c.id === comment.parent)
          if (parent) {
            parent.reply = comment
            delete comments[idx]
          }
        }
      })
    comments = comments.filter(x => x) // remove undefined elements
    const readerGrade = Math.round(comments.length ? comments.reduce((total, comment) => total + (parseInt(comment.acf.tahdet) || 0), 0) / comments.length : 0)
    this.setState({ comments, readerGrade })
  }

  componentDidMount () {
    this.loadComments(this.props)
  }

  componentWillReceiveProps (newProps) {
    if (newProps.id !== this.props.id) {
      this.loadComments(newProps)
    }
  }

  updateComment (newComment) {
    const idx = this.state.comments.findIndex((comment) => comment.id === newComment.id)
    if (idx >= 0) {
      const comments = this.state.comments
      comments[idx].acf = newComment.acf
      this.setState({ comments })
    } else {
      const idx2 = this.state.comments.findIndex((comment) => comment.reply && comment.reply.id === newComment.id)
      if (idx2 >= 0) {
        const comments = this.state.comments
        comments[idx2].reply.acf = newComment.acf
        this.setState({ comments })
      }
    }
  }

  render () {
    const { username, id, loggedIn } = this.props
    const { open, comments, readerGrade, page, isWriting } = this.state
    if (!id) {
      return null
    }
    const hasCommented = comments.some(comment => comment.author_name === username)
    const perPage = 10
    const shownComments = comments.slice(page * perPage, (page + 1) * perPage)
    const pages = Math.floor(comments.length / perPage) + 1

    return (
      <div styleName="main">
        <div styleName="user-comments">
          <div>{comments.length && readerGrade
            ? <Fragment>Lukijoidemme kokemuksia<br/>Keskiarvo: <span styleName="stars">{'★'.repeat(readerGrade || 0)}</span></Fragment>
            : <span styleName="with-icon"> Voit jakaa kokemuksiasi tästä tuotteesta muille {window.om_constants.siteNameGen} lukijoille!</span>}
          </div>
          <div>
            {comments.length && !open
              ? <button
                onClick={() => this.showComments(true)}>Lue {comments.length} arvostelu{comments.length > 1 ? 'a' : ''}</button>
              : null}
            {comments.length && open
              ? <button onClick={() => this.showComments(false)}>Piilota
              arvostelu{comments.length > 1 ? 't' : ''}</button>
              : null}
            {hasCommented
              ? null
              : (
                loggedIn
                  ? <button onClick={() => this.addComment()}>Arvostele tuote</button>
                  : <a href={WP.getLoginUrl()} styleName="with-icon"><span >Kirjaudu sisään ja arvostele</span></a>)}
          </div>
        </div>
        {isWriting
          ? <CustomForm
            id={id}
            thankYouTitle="Kiitos arvostelustasi."
            thankYouText="Arvostelu julkaistaan, kun toimitus on tarkastanut sen."
            header=""
            instructions=""
            showStars
            starsTitle="Anna yleisarvosana"
            commentTitle="Kirjoita arvostelu"
            commentPlaceholder=""
            cancel={() => this.setState({ isWriting: false })}
            autoOpen={true}/>
          : null}
        {open
          ? <div>
            {shownComments.map((comment, idx) =>
              <ProductComment
                key={comment.id}
                comment={comment}
                isLast={idx === comments.length - 1}
                admins={[]}
                hideActions={true}
                setComment={(comment) => this.updateComment(comment)}
                showStars
              />
            )}
            {pages > 1
              ? <div styleName="prev-next">
                {page > 0 ? <button onClick={() => this.setState({ page: page - 1 })}><span styleName="prev"></span></button> : null}
            Sivu {page + 1} / {pages}
                {page + 1 < pages ? <button onClick={() => this.setState({ page: page + 1 })}><span styleName="next"></span></button> : null}
              </div>
              : null}
          </div>
          : null}
      </div>
    )
  }
}

export default ProductComments
