import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { connect } from 'kea'
import Image from '../components/general/util/Image'
import application from '@otavamedia/om-component-library/lib/kea/application'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import { Link } from '../components/general/util/Links'
import BestProductPageLoader from '../components/general/util/BestProductPageLoader'
import BestProductLoader from '../components/general/util/BestProductLoader'
import { ErrorPlaceholder, withErrorBoundary } from '../components/general/util/ErrorBoundaries'
import TopRowAd from '../components/general/util/TopRowAd'
import TestCard from '../components/widgets/TestCard'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import { STATUS } from '@otavamedia/om-component-library/lib/lib/request-state'
import { pushPageDataToGTM, stripHTML } from '@otavamedia/om-component-library/lib/lib/utils'
import { ShowAll } from '../components/widgets/ShowAll'

import './UserCards.scss'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import track from 'react-tracking'
import RenderedError from '../components/general/util/RenderedError'

const getPageFromUrl = (link) => {
  const regex = /^(.*\/)([0-9]+)(\/)$/
  return link && parseInt(link.replace(regex, '$2'))
}

@connect({
  actions: [
    application, [
      'setViewData',
    ]
  ],
  props: [
    application, [
      'view',
    ],
    auth, [
      'premiumUser',
    ],
  ]
})
  /**
   * This creates the /lukijoilta page.
   * It fetches and shows some product cards (tuotekortit), memory cards (lukijoiden muistot) and question cards (vinkit)
   */
class UserCards extends Component {
  static propTypes = {
    view: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    setRendered: PropTypes.func.isRequired,
    premiumUser: PropTypes.bool,
  }

  state = {
    status: STATUS.NOT_REQUESTED,
    page: getPageFromUrl(window.location.href) || 1,
    tag: 'testipankki',
    parts: [
      {
        title: 'Käyttäjäarvostelut',
        type: 'productcards',
        items: [],
        isBest: false,
        ownGrade: true,
        link: '/kayttajaarvostelut/'
      },
      {
        title: 'Lukijoiden vinkit',
        type: 'questioncards',
        items: [],
        link: '/lukijoiden-vinkit/',
      },
      {
        title: 'Lukijoiden muistot',
        type: 'memorycards',
        items: [],
        link: '/lukijoiden-muistot/',
      },
    ]
  }

  componentDidMount () {
    pushPageDataToGTM({})
    this.loadData()
  }

  componentWillUnmount () {
    this.props.setRendered(false)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.location.key !== this.props.location.key) {
      this.loadData()
    }
  }

  async loadData () {
    try {
      this.getArticles()
      const postReq = await WP.getForURL(WP.url + '/lukijoilta')
      const post = postReq.data
      this.actions.setViewData(post)
    } catch (e) {
      this.setState({
        status: STATUS.ERROR,
        data: e,
      })
    }

    if (this.props.view.id) {
      WP.triggerHitCounter(this.props.view.id)
    }
  }

  async getArticles () {
    const { parts } = this.state

    try {
      this.setState({ status: STATUS.REQUESTED })
      const updatedParts = await Promise.all(parts.map(async (part) => {
        const post = await WP.getForURL(WP.url + part.link)
        const newPart = { ...part }

        newPart.categories = post.data.categoriesAvailable

        let articles = []
        if (part.type === 'productcards') {
          articles = (await WP.getProductCards()).data
        } else if (part.type === 'questioncards') {
          articles = (await WP.getQuestionCards()).data
        } else if (part.type === 'memorycards') {
          articles = (await WP.getMemoryCards()).data
        }

        if (articles.length !== 0 && articles.length > 3) {
          articles = articles.slice(0, 3)
        }

        newPart.items = articles
        return newPart
      }))
      this.setState({ parts: updatedParts }, () => this.props.setRendered(true))
    } catch (e) {
      console.log(e)
      this.setState({
        status: STATUS.ERROR,
        data: e,
      })
    }
  }

  renderArticles (parts) {
    const { status } = this.state

    const items =
      parts.map((part, i) => {
        return <div key={i} styleName="item-category">
          <div styleName="item-header">
            <Link to={{ link: part.link }}><h3>{part.title}</h3></Link>
            <ShowAll url={part.link} inline />
          </div>
          <div styleName="links">
            {part.categories && part.categories.map((category, i) =>
              <Link key={i} to={{ link: part.link + category.slug }}>{category.name}</Link>
            )}
          </div>
          <ul styleName="result-list">
            {part.items.map((p, i) => (
              <li styleName="result-item" key={`${i}-${p.id}`}>
                <Link to={p}>
                  <TestCard notShown={{ createdDate: p.parser !== 'memorycard', categories: true }} article={p} ownGrade={part.ownGrade}
                    topHeader={p.parser === 'memorycard' && p.comments && p.comments.length ? p.comments[p.comments.length - 1].author_name : null}/>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      })

    const loading = status === STATUS.REQUESTED

    return (
      <Fragment>
        <div data-loading={loading}>
          {items}
        </div>
      </Fragment>
    )
  }

  render () {
    const { status, parts } = this.state
    const { view } = this.props

    if (status !== STATUS.ERROR && (!view || !view.id)) {
      return <BestProductPageLoader />
    }

    const {
      content,
      title,
      excerpt,
      link,
      featuredMedia,
    } = view

    const fullLink = window.location.origin + link
    const metaTags = {
      'og:locale': 'fi_FI',
      'og:type': 'article',
      'og:title': stripHTML(title),
      'og:description': stripHTML(excerpt),
      'og:url': fullLink,
      'http:status': 200,
    }
    return (
      <Fragment>
        <Helmet>
          <title>
            {`${stripHTML(title)} - ${window.om_constants.siteName}`}
          </title>
          <link rel="canonical" href={fullLink} />
          <meta name="description" content={metaTags['og:description']}/>
          {Object.entries(metaTags).map(([key, value], i) => Array.isArray(value)
            ? value.map(x => <meta name={key} key={`${key}-${i}`} content={x} />)
            : <meta name={key} key={`${key}-${i}`} content={value} />
          )}
        </Helmet>
        <div styleName="main-image">
          {featuredMedia
            ? <Image data={featuredMedia} size={IMAGE_SIZE.LARGE} sizes={'(max-width: 1440px) 100vw, 1440px'}/>
            : null
          }
        </div>
        <div styleName='magazine-content'>
          <article styleName="article-container">
            <header>
              <h1><HTML>{title}</HTML></h1>
            </header>
            <div styleName='article-body'>
              <HTML>{content}</HTML>
            </div>
          </article>
          <section styleName="results">
            {parts.length
              ? this.renderArticles(parts)
              : status === STATUS.ERROR
                ? <RenderedError error={parts}/>
                : <BestProductLoader/>
            }
          </section>
        </div>
        <TopRowAd isArticleView={false} display={true}/>
      </Fragment>
    )
  }
}

export default track({ gtmContext: ['UserCards'] })(withErrorBoundary(
  UserCards,
  ErrorPlaceholder()
))
