import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { NavLink } from '../util/Links'
import rm404 from '../../../assets/pictures/404_RM_kuva.jpg'
import { history } from '../../../store'
import './404-page.scss'
import { connect } from 'kea'
import application from '@otavamedia/om-component-library/lib/kea/application'

export default @connect({
  props: [
    application, [
      'referrer',
    ],
  ]
})
class Page404 extends Component {
  static propTypes = {
    setRendered: PropTypes.func,
    referrer: PropTypes.array,
  }

  componentDidMount () {
    this.props.setRendered(true)
    const localReferrer = this.props.referrer.length > 1 ? this.props.referrer[this.props.referrer.length - 2] : null
    window.dataLayer.push({ event: '404page', referrer: localReferrer || document.referrer })
  }

  componentWillUnmount () {
    this.props.setRendered(false)
  }

  render () {
    return (
      <div styleName="container">
        <Helmet>
          <title>404 | Sivua ei löytynyt</title>
          <meta name="http:status" content="404" />
        </Helmet>

        <h1>Hups, sivua ei löytynyt (404)</h1>
        <p styleName="large-font">Nyt ei valitettavasti haku osunut naulan kantaan, emmekä löytäneet hakemaasi sivua.</p>
        <div styleName="button-row">
          <button onClick={() => history.goBack()} styleName="nav-button">Siirry takaisin</button>
          <NavLink to={{ link: '/' }} styleName="nav-button">Palaa etusivulle</NavLink>
        </div>
        <img src={rm404} alt="404" />
      </div>
    )
  }
}
