import React, { Component } from 'react'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import CrossLinks from '@otavamedia/om-component-library/lib/CrossLinks'
import { Link } from '../general/util/Links'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import track from 'react-tracking'

const CrossLinkElem = styled(CrossLinks)`
font-family: "Flama Semicond Semibold", sans-serif;
margin-bottom: 1rem;
.meta {
  font-family: "Flama Medium", sans-serif;
  border: none;
  padding-bottom: 0;
}
`

export default @track({ gtmContext: ['CrossLinkBox'] })
class CrossLinkBox extends Component {
  async getCrossLinks () {
    try {
      const links = await WP.getCrossLinks()
      links.forEach((l) => {
        if (!l.siteName) l.siteName = 'Metsästys ja Kalastus'
      })
      this.props.doneLoading()
      return links
    } catch (e) {
      this.props.doneLoading()
      return []
    }
  }

  render () {
    return <CrossLinkElem getCrossLinks={() => this.getCrossLinks()} LinkComponent={Link}/>
  }
}

CrossLinkBox.propTypes = {
  doneLoading: PropTypes.func.isRequired
}
