import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import MagazineCarouselLoader from '../general/util/MagazineCarouselLoader'
import StyledDigiMagazineCardCarousel from '../weeklyMagazine/StyledDigiMagazineCarousel'
import { CaretLeft, CaretRight } from '../widgets/Icons'

import WP from '@otavamedia/om-component-library/lib/lib/WP'

import './MagazineCarouselRow.scss'
import isEqual from 'lodash/isEqual'

class ThemeLoader extends Component {
  _mounted = false
  asyncSetState (...args) {
    // avoid setting state after unmount in case async operations attempts to do so
    if (this._mounted) this.setState(...args)
  }

  constructor (props) {
    super(props)
    this.state = {
      magazines: [],
      loading: null,
    }
  }

  componentDidMount () {
    this._mounted = true
    this.loadMagazines()
  }

  componentWillUnmount () {
    this._mounted = false
  }

  async loadMagazines () {
    this.setState({
      loading: true,
    })
    const magazines = (await WP.getThemeMagazines()).sort((a, b) => a.latest_post_date_gmt < b.latest_post_date_gmt ? 1 : -1)
    this.asyncSetState({
      magazines: magazines.map((mag, i) => {
        mag.className = 'left-card right-card'
        return mag
      }),
      loading: false,
    }, () => this.props.onLoad && this.props.onLoad(this.state))
  }

  componentWillReceiveProps (nextProps) {
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(this.state, nextState) || !isEqual(this.props, nextProps)
  }

  render () {
    const carouselClass = ['theme-carousel']

    if (this.props.highlightSelected) carouselClass.push('highlight-selected')

    return this.state.loading !== false
      ? <MagazineCarouselLoader/>
      : <StyledDigiMagazineCardCarousel className={carouselClass.join(' ')} initialSlide={this.props.initialSlide}
        onItemClick={this.props.onMagazineItemClick} magazines={this.state.magazines} arrowLeft={<CaretLeft color="red"/>}
        arrowRight={<CaretRight color="red"/>}/>
  }
}

ThemeLoader.propTypes = {
  onLoad: PropTypes.func,
  onMagazineItemClick: PropTypes.func,
  initialSlide: PropTypes.number,
  highlightSelected: PropTypes.bool,
}

export default withErrorBoundary(
  ThemeLoader,
  ErrorPlaceholder(null)
)
