import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './ScrollVideo.scss'

/**
 * Displays a video that can be controlled by scrolling the page up/down.
 * Caption boxes will scroll over the video.
 */
export default class ScrollVideo extends Component {
  static propTypes = {
    video: PropTypes.object
  }

  onScrollVideo (e) {
    window.scrollTimer = window.scrollTimer || []
    window.videoTargetPos = window.videoTargetPos || []
    const docs = document.getElementsByClassName('ScrollVideo_video-box')
    for (let i = 0; i < docs.length; i++) {
      const doc = docs[i]
      const video = doc.getElementsByTagName('video')[0]
      if (video.currentTime > window.videoTargetPos[i]) {
        const diff = (video.currentTime - window.videoTargetPos[i]) / 5
        if (diff > 0.01) {
          video.currentTime -= diff
          window.scrollTimer[i] = setTimeout(() => this.onScrollVideo(), 100)
        } else {
          video.currentTime = window.videoTargetPos[i]
          window.scrollTimer[i] = null
        }
      } else if (video.currentTime < window.videoTargetPos[i]) {
        const diff = (video.currentTime - window.videoTargetPos[i]) / 5
        if (Math.abs(diff) > 0.01) {
          video.currentTime -= diff
          window.scrollTimer[i] = setTimeout(() => this.onScrollVideo(), 100)
        } else {
          video.currentTime = window.videoTargetPos[i]
          window.scrollTimer[i] = null
        }
      } else {
        window.scrollTimer[i] = null
      }
    }
  }

  // set the position we eventually want to scroll to
  setScrollTarget () {
    const docs = document.getElementsByClassName('ScrollVideo_video-box')
    window.scrollTimer = window.scrollTimer || []
    window.videoTargetPos = window.videoTargetPos || []
    for (let i = 0; i < docs.length; i++) {
      const doc = docs[i]
      const video = doc.getElementsByTagName('video')[0]
      const oldPos = window.videoTargetPos[i] || 0
      if (doc.getBoundingClientRect().top < 0) {
        const pos = -doc.getBoundingClientRect().top / (doc.getBoundingClientRect().height - video.getBoundingClientRect().height)
        window.videoTargetPos[i] = Math.min(video.duration * pos, video.duration)
        if (!window.scrollTimer[i]) {
          window.scrollTimer[i] = setTimeout(() => this.onScrollVideo(), 100)
        }
      } else if (oldPos) {
        window.videoTargetPos[i] = 0
        if (!window.scrollTimer[i]) {
          window.scrollTimer[i] = setTimeout(() => this.onScrollVideo(), 100)
        }
      }
    }
  }

  componentDidMount () {
    document.onscroll = () => this.setScrollTarget()
  }

  render () {
    const { video } = this.props
    /* eslint-disable jsx-a11y/media-has-caption */
    return <div styleName="video-box">
      <div styleName="video-overlay">
        <video preload="auto" playsInline="" src={video.video_url}></video>
      </div>
      {video.captions.map((caption, key) => <div styleName={`text-overlay align-${caption.alignment}`} key={key}>
        {caption.caption ? <p style={{ backgroundColor: caption.background_color, color: caption.color }}>{caption.caption}</p> : null}
      </div>
      )}
    </div>
  }
}
