import React, { Component } from 'react'

import './WhyOrder.scss'
import eye from '../../assets/ui/eye.svg'
import book from '../../assets/ui/book.svg'
import tools from '../../assets/ui/tools.svg'
import question from '../../assets/ui/question.svg'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import { Link } from '../general/util/Links'
import { connect } from 'kea'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import PropTypes from 'prop-types'

export default @connect({
  props: [
    auth, [
      'premiumUser',
    ],
  ]
})
/**
 * Displays a "Why order RM" box.
 */
class WhyOrder extends Component {
  static propTypes = {
    premiumUser: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = {
      numberOfArticles: 205
    }
    if (!props.premiumUser) {
      this.getNumberOfTests()
    }
  }

  async getNumberOfTests () {
    const articleData = await WP.getForContext({
      id: 308, // id of 'testit' category in production
      taxonomy: 'categories',
      _meta: {
        type: 'term'
      }
    }, {
      per_page: 1,
      _fields: ['id'],
      type: ['post', 'om_digimag_post', 'om_theme_article']
    })
    this.setState({ numberOfArticles: articleData ? articleData.headers['x-wp-total'] : 205 })
  }

  render () {
    if (this.props.premiumUser) {
      return null
    }
    const blocks = [
      {
        text: 'Näköislehdet vuodesta 2003',
        img: eye,
        hover: 'Lue kaikki artikkelit 20 vuoden ajalta',
        url: '/arkisto/'
      },
      {
        text: `${this.state.numberOfArticles} testiä ja vertailua`,
        img: tools,
        hover: 'Jatkuvasti päivittyvässä testipankissa on koko ajan uutta luettavaa',
        url: '/testit/'
      },
      {
        text: '12 lehteä vuodessa',
        img: book,
        hover: 'Digilehdessä on enemmän luettavaa kuin painetussa lehdessä',
        url: '/digilehdet/'
      },
      {
        text: 'Käytännöllisiä neuvoja ja remonttivinkkejä',
        img: question,
        hover: 'Asiantuntevia ohjeita remontointiin ja rakentamiseen',
        url: '/kategoria/vinkit/'
      },
    ]
    return <div styleName="why-order">
      <h3>Miksi tilata {window.om_constants.abbreviation}?</h3>
      <p>TM Rakennusmaailma tarjoaa rahanarvoista tietoa ja on rakentamisen sekä remontoimisen lahjomaton asiantuntija.</p>
      <div styleName="blocks">
        {blocks.map((block, key) => <div key={key} styleName="hover-over">
          <div>
            <Link to={{ link: block.url }}>
              <img src={block.img} alt=""/>
              <p>{block.text}</p>
            </Link>
          </div>
          <div styleName="hover">{block.hover}
            <div styleName="caret"></div>
          </div>
        </div>
        )}
      </div>
      <Link to={{ link: '/tarjous?utm_source=tmfi_syyt_tilata&utm_medium=syyt_tilata&utm_campaign=muuritarjous' }}>Tilaa TM Rakennusmaailma</Link>
    </div>
  }
}
