import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import track from 'react-tracking'
import { isEmpty } from 'lodash/lang'

import { Link as RLink, NavLink as RNavLink } from 'react-router-dom'
import { connect } from 'kea'
import application from '@otavamedia/om-component-library/lib/kea/application'
import articleTypes from '../article/ArticleTypes';

const kea = {
  actions: [
    application, [
      'setViewData',
      'setRendered'
    ],
  ],
  props: [
    application, [
      'view',
    ],
  ]
}
const propTypes = {
  to: PropTypes.object.isRequired,
  sameWindow: PropTypes.bool,
  clickLocation: PropTypes.string,
  className: PropTypes.any
}

const getPageType = (view) => {
  const { articleType, _meta, taxonomy, type } = view
  if (articleType) {
    return articleType === articleTypes.SPECIAL ? 'erikoistaitto' : 'artikkeli'
  }
  if (_meta) {
    if (taxonomy === 'category') {
      return 'kategoria'
    } else if (taxonomy === 'post_tag') {
      return 'avainsana'
    }
  } else if (type === 'index') {
    return 'etusivu'
  } else {
    return 'sivu'
  }
}

const generateLinkEventHandlers = (props) => {
  const { setViewData, disableStateChange, to, clickLocation, view } = props
  const pageType = getPageType(view)
  let isMiddleClick = false
  const onMouseDown = (e) => {
    isMiddleClick = false

    if (e.button === 1) {
      // console.log('clicked with middle button')
      isMiddleClick = true
    } else if (e.button === 0) {
      // console.log('clicked with primary button')
    }

    if (props.onMouseDown) {
      props.onMouseDown(e, props)
    }
  }

  const onClick = (e) => {
    const { altKey, ctrlKey, metaKey, shiftKey } = e
    const modifiers = [altKey, ctrlKey, metaKey, shiftKey]
    props.tracking.trackEvent({ event: 'navigation', link_url: to.link, element: clickLocation, page_subtype: pageType })

    props.actions.setRendered(false)
    if (props.onClick) {
      props.onClick(e, props)
    }

    /*
     * If any modifiers are used, the user expects a new tab,
     * window or to download the page contents as a file.
     */
    if (!disableStateChange && !isMiddleClick && !modifiers.some(Boolean)) {
      setViewData({ link: to.link })
    }
  }

  return {
    onClick,
    onMouseDown,
  }
}

const Link = track()(connect(kea)((props) => {
  const { actions, to, children, className, onKeyDown, id, onClick, demo, tracking, clickLocation, view } = props
  const pageType = getPageType(view)
  const { setViewData } = actions
  const [intersectionObserver, setIntersectionObserver] = useState(false)
  const reference = useRef()

  if (!to.link) {
    return <span className={className}>{children}</span>
  }

  function onIntersectionChange (entries) {
    if (entries[0].isIntersecting && entries[0].intersectionRatio >= 0.5) {
      const context = tracking.getTrackingData().gtmContext
      this.unobserve(reference.current)
      window.dataLayer.push({
        event: 'impression',
        post_id: to.id,
        title: to.title,
        link_url: to.link,
        location: context && context[context.length - 1],
        element: clickLocation,
      })
    }
  }

  useEffect(() => {
    let obs
    if (reference.current && !intersectionObserver) {
      obs = new IntersectionObserver(onIntersectionChange, {
        threshold: 0.5,
      })
      setIntersectionObserver(obs)
      obs.observe(reference.current)
    }

    return () => {
      if (reference.current && intersectionObserver) {
        intersectionObserver.unobserve(reference.current)
      }
    }
  }, [reference])

  const isExternal = to.link && to.link.indexOf && (to.link.indexOf('http') === 0 || to.link.indexOf('mailto:') === 0)
  const Tag = demo ? 'span' : (isExternal ? 'a' : RLink)
  const tagProps = isExternal
    ? {
      href: to.link,
      ref: reference,
      target: '_blank',
      rel: 'noreferrer noopener',
      onKeyDown,
      onClick: demo
        ? undefined
        : () => {
          tracking.trackEvent({
            event: 'navigation',
            link_url: to.link,
            element: clickLocation,
            page_subtype: pageType
          })
          onClick && onClick()
        }
    }
    : {
      to: demo ? undefined : to.link,
      innerRef: reference,
      id,
      onKeyDown,
      ...(demo
        ? []
        : generateLinkEventHandlers({
          ...props,
          setViewData,
        }))

    }

  return (
    <Tag {...tagProps} className={className}>
      {children}
    </Tag>
  )
}))

Link.propTypes = propTypes

const NavLink = track()(connect(kea)((props) => {
  const { actions, to, children, className, rel, sameWindow, onClick, demo, tracking, clickLocation } = props
  const { setViewData } = actions
  const [intersectionObserver, setIntersectionObserver] = useState(false)
  const reference = useRef()

  if (isEmpty(to) || !to.link) {
    console.warn('NavLink "to link" not specified.')
    return null
  }

  function onIntersectionChange (entries) {
    if (entries[0].isIntersecting && entries[0].intersectionRatio >= 0.5) {
      const context = tracking.getTrackingData().gtmContext
      this.unobserve(reference.current)
      window.dataLayer.push({
        event: 'impression',
        post_id: to.id,
        title: to.title,
        link_url: to.link,
        location: context && context[context.length - 1],
        element: clickLocation,
      })
    }
  }

  useEffect(() => {
    let obs
    if (reference.current && !intersectionObserver) {
      obs = new IntersectionObserver(onIntersectionChange, {
        threshold: 0.5,
      })
      setIntersectionObserver(obs)
      obs.observe(reference.current)
    }

    return () => {
      if (reference.current && intersectionObserver) {
        intersectionObserver.unobserve(reference.current)
      }
    }
  }, [reference])

  const isExternal = to.link && to.link.indexOf && (to.link.indexOf('http') === 0 || to.link.indexOf('mailto:') === 0)
  const Tag = demo ? 'span' : (isExternal ? 'a' : RNavLink)
  const tagProps = isExternal
    ? {
      href: to.link,
      ref: reference,
      target: sameWindow ? undefined : '_blank',
      rel: 'noreferrer noopener',
      onClick: demo ? undefined : onClick
    }
    : {
      to: demo ? undefined : to.link,
      innerRef: reference,
      rel,
      ...(demo
        ? []
        : generateLinkEventHandlers({
          ...props,
          setViewData,
        }))
    }

  return (
    <Tag {...tagProps} className={className}>
      {children}
    </Tag>
  )
}))

NavLink.propTypes = propTypes

export {
  Link,
  NavLink,
}
