import React, { Component } from 'react'
import PropTypes from 'prop-types'

import isEqual from 'lodash/isEqual'
import { connect } from 'kea'
import querystring from 'querystring'
import debouncedRender from 'react-debounce-render'
import application from '@otavamedia/om-component-library/lib/kea/application'

import WP from '@otavamedia/om-component-library/lib/lib/WP'
import ArticleLoader from '../components/general/util/ArticleLoader'
import MagazineArticleLoader from '../components/general/util/MagazineArticleLoader'
import Article from './Article'

import RenderedError from '../components/general/util/RenderedError'
import renderDebugger from '@otavamedia/om-component-library/lib/lib/debug-render'
import { STATUS } from '@otavamedia/om-component-library/lib/lib/request-state'
import magazineStore from '@otavamedia/om-component-library/lib/kea/weeklyMagazine'
import flatMap from 'lodash/flatMap'
import { pushPageDataToGTM } from '@otavamedia/om-component-library/lib/lib/utils'
import auth from '@otavamedia/om-component-library/lib/kea/auth'

@connect({
  actions: [
    application, [
      'setViewData',
      'updateResolverStatus',
    ],
  ],
  props: [
    auth, [
      'premiumUser',
      'loggedIn',
    ],
    magazineStore, [
      'contents as magazine'
    ],
    application, [
      'view',
    ],
  ]
})
@renderDebugger
class MagResolver extends Component {
  state = {
    routeComponent: null,
    componentProps: {},
    isPreview: false,
    status: STATUS.NOT_REQUESTED,
  }

  static propTypes = {
    setRendered: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    view: PropTypes.object,
    history: PropTypes.object,
    magazine: PropTypes.object,
    loggedIn: PropTypes.bool,
    actions: PropTypes.object,
    premiumUser: PropTypes.bool,
  }

  viewUpdating = false

  async componentDidMount () {
    this.doRouting(this.props)
    // this.chooseComponent()
  }

  componentWillReceiveProps (nextProps) {
    const viewNotEqual = !isEqual(this.props.view, nextProps.view)
    const locationNotEqual = !isEqual(this.props.location.pathname, nextProps.location.pathname)

    if (viewNotEqual) {
      // console.log('Resolver: view has changed, will not route again')
      this.viewUpdating = true
    } else {
      this.viewUpdating = false
    }

    if (locationNotEqual || !isEqual(this.props.magazine && this.props.magazine.toc, nextProps.magazine && nextProps.magazine.toc)) {
      this.doRouting(nextProps)
    }
  }

  async doRouting (props) {
    if (!props.magazine || !props.magazine.toc || (props.magazine.link && !props.location.pathname.includes(props.magazine.link))) {
      // can't load article before the correct magazine has been loaded
      return
    }
    const { location, setRendered, magazine } = props
    const { setViewData, updateResolverStatus } = this.actions

    try {
      const qs = location.search && querystring.parse(location.search.substring(1))

      this.setState({
        isPreview: Boolean(qs.preview),
        status: STATUS.REQUESTED
      })

      updateResolverStatus(STATUS.REQUESTED)
      const articleInToc = flatMap(magazine.toc, 'articles').find((article) => article.link === location.pathname)
      const url = articleInToc
        ? WP.url + articleInToc.originalLink + ((qs.p && qs.preview) ? location.search : '')
        : WP.url + location.pathname + ((qs.p && qs.preview) ? location.search : '')
      const { data } = await WP.getForURL(url, { shared: qs.shared }, true)

      if (data) {
        if (data.error) {
          updateResolverStatus(STATUS.ERROR)
          throw data.error
        }

        const routeComponent = Article
        const componentProps = {
          doneLoading: () => this.props.setRendered(true)
        }

        pushPageDataToGTM(data, this.props.premiumUser)

        this.setState({
          status: STATUS.DONE,
          routeComponent,
          componentProps,
        }, () => {
          setViewData(data)
        })
      }
    } catch (e) {
      this.setState({
        status: STATUS.ERROR,
        componentProps: { error: e },
      }, () => setRendered(true))
    }

    updateResolverStatus(STATUS.DONE)
  }

  render () {
    const { routeComponent: RenderedComponent, componentProps, status } = this.state

    if (status === STATUS.ERROR) {
      return <RenderedError {...componentProps} />
    } else if (status !== STATUS.DONE || !RenderedComponent || this.viewUpdating || (!this.props.view || !this.props.view.id)) {
      if (this.props.match.path === '/lehti/:number/:article') {
        return <MagazineArticleLoader />
      } else if (this.props.match.path === '/mainoslehti/:number/:article') {
        return <MagazineArticleLoader />
      } else {
        return <ArticleLoader />
      }
    }

    return (
      <RenderedComponent {...componentProps} history={this.props.history} />
    )
  }
}

// export default Resolver
export default debouncedRender(MagResolver)
