import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { connect } from 'kea'
import ReactPlaceholder from 'react-placeholder'
import format from 'date-fns/format'
import get from 'lodash/get'
import { fixArticleUrl, pushPageDataToGTM } from '@otavamedia/om-component-library/lib/lib/utils'
import application from '@otavamedia/om-component-library/lib/kea/application'
import magazineStore from '@otavamedia/om-component-library/lib/kea/weeklyMagazine'
import Row from './Row'
import Title from '../general/util/Title'
import Image from '../general/util/Image'
import WeeklyMagazineLoader from '../general/util/WeeklyMagazineLoader'
import TopRowAd from '../general/util/TopRowAd'
import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import { is } from '@otavamedia/om-component-library/lib/lib/errors'
import './WeeklyMagazine.scss'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import track from 'react-tracking'
import RenderedError from '../general/util/RenderedError'

@track({ gtmContext: ['WeeklyMagazine'] })
@connect({
  props: [
    magazineStore, [
      'isLoading',
      'nextMagazines',
      'contents as magazine'
    ]
  ],
  actions: [
    application, [
      'setRendered'
    ],
    magazineStore, [
      'loadMagazine'
    ]
  ],
})
class WeeklyMagazine extends Component {
  componentDidMount () {
    pushPageDataToGTM({})
    if (this.props.match.params.number) {
      this.loadData(this.props)
    }
  }

  componentDidUpdate () {
    const { isLoading } = this.props

    if (!isLoading) {
      const { setRendered } = this.actions
      setRendered(true)
    }
  }

  componentWillUnmount () {
    this.props.setRendered(false)
  }

  componentWillReceiveProps (nextProps) {
    const link = get(nextProps, 'magazine.link')
    if ((!nextProps.magazine.error) &&
      (!link || link.includes('/teemalehti') || link.includes('/lehti/')) &&
      nextProps.match.params.number &&
      (nextProps.match.params.number !== this.props.match.params.number || (!nextProps.isLoading && !get(nextProps, 'magazine.toc')))) {
      this.loadData(nextProps)
    }
  }

  loadData = (props) => {
    // const { isAdMagazine, isThemeMagazine } = this.props
    // const endpoint = isAdMagazine ? 'admagazine' : (isThemeMagazine ? 'thememagazine' : 'digimag')

    // this.actions.loadMagazine(props.match.params.number, props.match.path, endpoint)
  }

  getNextMagName = nextMagazine => {
    return nextMagazine.isPublished
      ? `Seuraavassa numerossa | ${window.om_constants.abbreviation} ${nextMagazine.name.split('/')[0]}`
      : `Seuraavassa numerossa | ${window.om_constants.abbreviation} ${nextMagazine.name.split('/')[0]} ilmestyy ${format(nextMagazine.publishDate, 'D.M.')}`
  }

  render () {
    const { magazine, isLoading, nextMagazines, isAdMagazine, isThemeMagazine } = this.props
    const {
      sections,
      primaryImage,
      layoutActivated,
      error,
      nextMagPreview,
    } = magazine
    const isNormalMagazine = !isAdMagazine && !isThemeMagazine

    const nextMagazine = nextMagazines && nextMagazines.length ? nextMagazines[0] : undefined

    if (is.error(error)) {
      return <RenderedError error={error}/>
    }

    if (sections && sections.length && nextMagazine && nextMagPreview.length) {
      nextMagPreview.forEach(item => {
        if (nextMagazine.isPublished && get(item[item.type], 'post.article')) {
          item[item.type].post.link = nextMagazine.isPublished ? fixArticleUrl(item[item.type].post.article.link) : undefined
        } else {
          item[item.type].post.link = nextMagazine.isPublished ? `/lehti/${nextMagazine.slug}/` : undefined
        }
        item[item.type].post.vignette = `${window.om_constants.abbreviation} ${nextMagazine.name.split('/')[0]}`
      })
    }
    const magazineNumber = this.props.match.params.number
    const metaTags = magazine.sections && magazine.sections.length
      ? {
        'og:locale': 'fi_FI',
        'og:type': 'article',
        'og:title': isThemeMagazine ? window.om_constants.siteNameGen + ' teemalehti - ' + magazine.name : window.om_constants.siteName + ' - ' + magazine.name,
        'og:description': '',
        'og:url': window.location.origin + magazine.link,
        'og:image': magazine.coverImage && magazine.coverImage.src,

        // og:type is article, more tags for it:
        'article:published_time': magazine.publishDate,
        'article:modified_time': magazine.updatedDate,

        'http:status': 200,
      }
      : null
    return (
      <Fragment>
        <Helmet>
          <title>
            {magazine.name ? (isThemeMagazine ? magazine.name + ' - ' + window.om_constants.siteName : `${window.om_constants.abbreviation} ${magazine.name} - ${window.om_constants.siteName}`) : window.om_constants.siteName}
          </title>
          <meta name="http:status" content="200" />
          {metaTags && Object.entries(metaTags).map(([key, value], i) => Array.isArray(value)
            ? value.map(x => <meta property={key} key={`${key}-${i}`} content={x} />)
            : <meta property={key} key={`${key}-${i}`} content={value} />)}
        </Helmet>
        <TopRowAd display={!isLoading && !isAdMagazine}/>
        <ReactPlaceholder ready={!isLoading} customPlaceholder={<WeeklyMagazineLoader/>}>
          <Fragment>
            {sections && sections.length
              ? (
                <Fragment>
                  <div styleName="main-image">
                    {primaryImage
                      ? <Image data={primaryImage} size={IMAGE_SIZE.LARGE} sizes={'(max-width: 1440px) 100vw, 1440px'}/>
                      : null
                    }
                  </div>
                  <div styleName='magazine-content'>
                    {sections.map((section, index) => {
                      return (
                        <Fragment key={`section-${index}`}>
                          {section.title
                            ? <Title text={section.title} displayLogo/>
                            : (index ? null : <h2 className="screen-reader-text">Pääjutut</h2>)}
                          {section.rows && section.rows.map((row, index) => (
                            <Row magazine={magazine.slug} key={`row-${index}`} row={row} showDate={isThemeMagazine} latest={magazine.updatedDate} />)
                          )}
                        </Fragment>
                      )
                    })}
                  </div>
                  {isNormalMagazine && nextMagazine && nextMagPreview.length
                    ? (
                      <div styleName={`magazine-content ${nextMagazine.isPublished ? '' : 'next-magazine-content'}`}>
                        <Title text={this.getNextMagName(nextMagazine)} displayLogo />
                        <Row magazine={magazine.slug} row={nextMagPreview} showDate={isThemeMagazine} latest={magazine.updatedDate} />
                      </div>
                    )
                    : null
                  }
                </Fragment>
              )
              : sections && !layoutActivated
                ? (
                  <p>Lehden koontinäkymä ei ole aktiivinen.</p>
                )
                : (
                  isNormalMagazine
                    ? <span>Numerolla {magazineNumber} ei löytynyt lehteä</span>
                    : <span>Lehteä ei löytynyt</span>
                )}
          </Fragment>
        </ReactPlaceholder>
      </Fragment>
    )
  }
}

WeeklyMagazine.propTypes = {
  magazine: PropTypes.object,
  isLoading: PropTypes.bool,
  match: PropTypes.object,
  nextMagazines: PropTypes.array,
  isAdMagazine: PropTypes.bool,
  isThemeMagazine: PropTypes.bool,
  setRendered: PropTypes.func,
}

WeeklyMagazine.defaultProps = {
  isAdMagazine: false,
  isThemeMagazine: false
}

export default withErrorBoundary(
  WeeklyMagazine,
  ErrorPlaceholder(null)
)
