import React, { Component, Fragment } from 'react'
import { Link } from '../general/util/Links'
import { SubscriberIcon } from './Icons'
import ArticleMetaData from '../general/article/ArticleMetadata'
import ArticleThumbnail from '../general/article/ArticleThumbnail'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import './NoAdBlocker.scss'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import { getURL } from '@otavamedia/om-component-library/lib/lib/WPClient'
import { connect } from 'kea'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import track from 'react-tracking'
import PropTypes from 'prop-types'
import { isHeadless } from '@otavamedia/om-component-library/lib/util/env'
import adsLogic from '@otavamedia/om-component-library/lib/kea/ads'

const shuffle = array => {
  /* modern version of the Fisher-Yates shuffle */
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const x = array[i]
    array[i] = array[j]
    array[j] = x
  }
  return array
}

export default @track({ gtmContext: 'NoAdBlocker' })
@connect({
  props: [
    auth, [
      'premiumUser',
    ],
    adsLogic, [
      'isAdBlockTaken'
    ],
  ],
  actions: [
    adsLogic, [
      'setAdBlockTaken'
    ]
  ]
})
class NoAdBlocker extends Component {
  canShowAdblocker = false
  _mounted = true

  static propTypes = {
    premiumUser: PropTypes.bool,
    isAdBlockTaken: PropTypes.object,
    actions: PropTypes.object,
    fallbackElement: PropTypes.any,
  }

  constructor (props) {
    super(props)
    this.state = {
      articles: [],
      adBlocker: true
    }
    if (!props.isAdBlockTaken) {
      this.canShowAdblocker = true
      props.actions.setAdBlockTaken(this)
    }
    this.loadArticles()
  }

  async loadArticles () {
    try {
      const urls = await WP.getNoAdBlockArticles()
      let articles = await Promise.all(urls.map(async (url, idx) => {
        let params = ''
        try {
          const urlObj = new URL(url.replace(/[^a-zA-Z0-9.:/?=\-_]/g, ''))
          url = urlObj.pathname
          params = urlObj.search
        } catch (e) {
          console.log('Error parsing url ', url)
        }
        const article = (await WP.getForURL(getURL(url))).data
        article.link = url + params
        return article
      }))
      articles = shuffle(articles).slice(0, 1)

      if (!this._mounted) {
        return
      }
      if (this.state) {
        this.setState({ urls, articles })
      } else {
        this.state = { urls, articles }
      }
    } catch (e) {
      console.log('Failed loading adblock articles', e)
    }
  }

  componentDidMount () {
    this._mounted = true
    if (isHeadless() || (window.googletag && window.googletag.pubadsReady)) {
      this.setState({ adBlocker: false })
    } else {
      const interval = setInterval(() => window.googletag && window.googletag.pubadsReady && this.setState({ adBlocker: false }) && clearInterval(interval), 1000)
    }
  }

  componentWillUnmount () {
    this._mounted = false
    if (this.canShowAdblocker) {
      this.actions.setAdBlockTaken(null)
    }
  }

  render () {
    const { articles, adBlocker } = this.state
    const { premiumUser, fallbackElement: FallbackElement } = this.props

    if (!this.canShowAdblocker) {
      return FallbackElement ? <FallbackElement /> : null
    }
    if (adBlocker) {
      return (
        <div styleName="main">
          <h3>Hyvä mainoksenesto-ohjelman käyttäjä!</h3>
          <p>Ymmärrämme, ettet tullut tänne katsomaan mainoksia. {window.om_constants.siteNameGen} verkkopalvelua kuitenkin
             rahoitetaan sekä mainostuloilla että tilausmaksuilla.</p>
          <p>Arvostaisimme, jos voisit kytkeä mainoksenesto-ohjelman pois päältä sivuiltamme, jotta voimme
             jatkossakin tarjota vertailuja, testejä ja muuta laadukasta sisältöä.</p>
        </div>
      )
    }
    return !premiumUser && articles && articles.length
      ? <div styleName="main">
        <h3>Kiitos, kun et käytä mainonnan esto-ohjelmia!</h3>
        <p>{window.om_constants.siteNameGen} palvelua rahoitetaan sekä mainostuloilla että tilausmaksuilla.
           Kiitokseksi tarjoamme ilmaiseksi tämän maksumuurin takaisen jutun.</p>
        <div styleName="editors-picks-list">
          <ul>
            {articles && articles.map((article, idx) => (
              <li key={idx}>
                <article styleName="editors-picks-item">
                  <div styleName="meta-row">
                    <ArticleMetaData categories={article.categories} date={article.createdDate}
                      magazineLabel={article.printmag ? article.printmag.name : null}/>
                  </div>
                  <Fragment>
                    <Link to={article}>
                      <span styleName='thumbnail'><ArticleThumbnail article={article}/></span>
                      <h5><HTML>{article.title}</HTML></h5>
                      {article.forSubscribers && <div styleName="subscriber-icon-container"><SubscriberIcon/></div>}
                    </Link>
                  </Fragment>
                </article>
              </li>
            ))}
          </ul>
        </div>
      </div>
      : null
  }
}
