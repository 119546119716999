import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'
import headerLogic from '@otavamedia/om-component-library/lib/kea/header'
import { Link, NavLink } from '../general/util/Links'
import BurgerMenu from './BurgerMenu'
import SearchMenu from '../general/search/SearchMenu'
import { SearchIcon, SiteLogo, UserIconWhite } from '../widgets/Icons'
import NavigationTop from './NavigationTop'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import './NavigationBurger.scss'

export default @connect({
  props: [
    headerLogic, [
      'menus',
      'burgerMenuOpen',
      'searchOpen',
    ],
    auth, [
      'loggedIn',
      'accessLevel'
    ]
  ],
  actions: [
    headerLogic, [
      'setBurgerMenuOpen',
      'setSearchOpen',
    ],
  ],
})
class NavigationBurger extends Component {
  static propTypes = {
    menus: PropTypes.array,
    headerState: PropTypes.string,
    burgerMenuOpen: PropTypes.bool,
    searchOpen: PropTypes.bool,
    loggedIn: PropTypes.bool,
    accessLevel: PropTypes.bool,
  }

  toggleSearch = () => {
    this.openSearch(!this.props.searchOpen)
  }

  openSearch = (open = true) => {
    this.actions.setSearchOpen(open)
  }

  componentWillUnmount () {
    if (this.props.searchOpen) {
      this.openSearch(false)
    }
  }

  render () {
    const { headerState, searchOpen, loggedIn, accessLevel } = this.props
    const styles = ['mobile-navigation', headerState]

    return (
      <div styleName={styles.join(' ')}>
        <Fragment>
          <div styleName="tablet-row">
            <NavigationTop />
          </div>
          <div styleName="navigation-row">
            <div styleName="logo">
              <NavLink to={{ link: '/' }}>
                <SiteLogo />
              </NavLink>
            </div>
            <div styleName="buttons">
              {accessLevel > 2
                ? null
                : <div styleName="">
                  <Link to={{ link: '/tilaa/' }}
                    onClick={(e) => {
                      let promoPosition = '-single'
                      if (window.location.pathname === '/') {
                        promoPosition = '-etusivu'
                      } else if (window.location.pathname.includes('/kategoria/') || window.location.pathname.includes('/avainsana/')) {
                        promoPosition = '-kategoria'
                      }
                      window.location.href = 'https://tilaus.rakennusmaailma.fi/tilaus?promoID=OM_tilaa-painike&promoName=OM_RM&promoCreative=onlinetarjous&promoPosition=header' + promoPosition
                      e.preventDefault()
                      return false
                    }}
                    styleName="order-button">Tilaa</Link>
                </div>
              }
              <div styleName="">
                {loggedIn
                  ? <Link to={{ link: '/tili' }} styleName="nav-login-button"><UserIconWhite/></Link>
                  : <a styleName="nav-login-button" href={WP.getLoginUrl()}><UserIconWhite/></a>
                }
              </div>
              <div styleName="search">
                <button id="nav-bar-search-button" styleName="search-button" onClick={this.toggleSearch} >
                  {searchOpen ? <span>&times;</span> : <SearchIcon />}
                </button>
              </div>
              <div styleName="menu">
                <BurgerMenu
                  mainMenu
                  menus={this.props.menus}
                  open={this.props.burgerMenuOpen}
                />
              </div>
            </div>
          </div>
        </Fragment>
        <div styleName="search-row">
          <SearchMenu
            transitionInStyle={{
              transition: 'bottom 0.3s ease',
              bottom: 0,
            }}
            transitionOutStyle={{
              transition: 'bottom 0.3s ease',
              bottom: '100%',
            }}
            onTransitionEnd={this.transitionEnd}
            closeMenu={this.toggleSearch}
            open={this.props.searchOpen}
          />
        </div>
      </div>
    )
  }
}
