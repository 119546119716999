import React, { Component } from 'react'
import PropTypes from 'prop-types'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import './WeightTable.scss'
import Loadable from 'react-loadable'
import styled from 'styled-components'

const DynamicWeightTableData = Loadable({
  loader: () => import('@otavamedia/om-component-library/lib/WeightTableData'),
  // eslint-disable-next-line react/display-name
  loading: () => <div></div>,
})

const StyledWeightTableData = styled(DynamicWeightTableData)`
  table button {
    height: 100%;
    width: 100%;
    background: transparent;
    border: none;
    color: rgb(0, 0, 255);
    line-height: 14px;
    font-size: 14px;
    padding: 1rem 0.75rem 0.75rem;
  }
  table .with-button {
    padding: 0 !important;
  }
  .button {
    @include button($padding: 0.5rem 0.5rem);
    margin: 0 0.5rem 0.25rem 0;
  }
  .link {
    background: transparent;
    color: rgb(0, 0, 255);
    line-height: 14px;
    font-size: 14px;
    border: none;
  }
`

class WeightTable extends Component {
  static propTypes = {
    data: PropTypes.object
  }

  render () {
    const { data } = this.props
    if (!data) {
      return null
    }

    const { otsikko: title, kuvaus: description, taulukko: table, vertailuteksti: text } = data
    if (table.includes('[["')) {
      let  newTable = JSON.parse(table)
      // remove extra columns
      const firstEmpty = newTable[0].findIndex((x) => x === '')
      newTable = newTable.map(row => row.slice(0, firstEmpty))

      // extract options = first column
      const options = newTable.map(row => row[0])
      // extract weights = second column
      const weights = newTable.map(row => row[1])

      // extract productData: columns to rows, skip first to columns
      newTable = newTable.map(row => row.slice(2))
      newTable = newTable[0].map((_, colIndex) => newTable.map(row => row[colIndex]))

      return <StyledWeightTableData data={{ products: newTable, options, weights }}/>
    }

    return <div className="weight-table">
      <h3><HTML>{title}</HTML></h3>
      <p><HTML>{description}</HTML></p>
      <HTML>{table}</HTML>
      <HTML>{text}</HTML>
    </div>
  }
}

export default WeightTable
