import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Title from '../general/util/Title'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import { NavLink } from '../general/util/Links'
import { ArrowRightIcon, ArrowRightRedIcon } from '../widgets/Icons'
import '../navigation/WeeklyMagazineContentsMenu.scss'
import track from 'react-tracking'
import diff from 'date-fns/difference_in_days'

/**
 * Digimag Table Of Contents component
 **/
class TableOfContents extends Component {
  renderVignetteAndTopic = item => {
    if (item.isEquation) {
      return <span styleName="vignette">Puheenaihe </span>
    }

    if (item.vignette && item.topic) {
      return (
        <Fragment>
          <span styleName="vignette"><HTML>{item.vignette}</HTML> </span>
          <span><HTML>{item.topic}</HTML> &mdash; </span>
        </Fragment>
      )
    } else if (item.vignette && !item.topic) {
      return <span styleName="vignette"><HTML>{item.vignette}</HTML> </span>
    } else {
      return null
    }
  }

  render () {
    const { onDarkBackground, isThemeMag } = this.props
    if (!this.props.contents || !this.props.contents[0]) {
      return null
    }
    return this.props.contents.map((section, index) => (
      <Fragment key={`section-${index}`}>
        <Fragment>
          {index === 0 && !section.title && this.props.addMainSectionTitle
            ? <Title key="main-items-title" text="Pääjutut" />
            : section.title ? <Title text={section.title} /> : null
          }
          <ul styleName={onDarkBackground ? 'toc-list dark' : 'toc-list'}>
            {section.articles.map((item) => {
              const isNew = isThemeMag && item.date && diff(new Date(), item.date) < 8
              const isUpdated = isThemeMag && !isNew && item.modifiedDate && diff(new Date(), item.modifiedDate) < 4
              return (
                <li styleName="article-link" key={`toc-item-${item.link}`}>
                  <NavLink to={item}>
                    {onDarkBackground
                      ? <ArrowRightRedIcon />
                      : <ArrowRightIcon />
                    }
                    <span styleName="title">
                      {this.renderVignetteAndTopic(item)}<HTML>{item.title}</HTML>

                      {isNew ? <div styleName="latest">Uusi</div> : null}
                      {isUpdated ? <div styleName="latest">Päivitetty</div> : null}
                    </span>

                  </NavLink>
                </li>
              )
            })}
          </ul>
        </Fragment>
      </Fragment>
    ))
  }
}

TableOfContents.propTypes = {
  contents: PropTypes.array.isRequired,
  addMainSectionTitle: PropTypes.bool,
  onDarkBackground: PropTypes.bool,
  isThemeMag: PropTypes.bool,
}

export default track({ gtmContext: ['TableOfContents'] })(TableOfContents)
