import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'
import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import SocialLinks from '../widgets/SocialLinks'
import { UserIcon } from '../widgets/Icons'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import { Link, NavLink } from '../general/util/Links'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import track from 'react-tracking'

import './NavigationBar.scss'

@track({ gtmContext: ['NavigationTop'] })
@connect({
  props: [
    auth, [
      'loggedIn',
      'username',
    ],
  ],
})
class NavigationTop extends Component {
  static propTypes = {
    loggedIn: PropTypes.bool,
    username: PropTypes.string,
  }

  render () {
    const { loggedIn } = this.props

    return (
      <Fragment>
        <div styleName="logos">

        </div>
        <div styleName="top-nav">
          <NavLink to={{ link: '/toimituksen-yhteystiedot/' }} styleName="top-navigation-item"><span>Toimitus</span></NavLink>
          <NavLink to={{ link: '/tilaa/' }}
            onClick={(e) => {
              let promoPosition = '-single'
              if (window.location.pathname === '/') {
                promoPosition = '-etusivu'
              } else if (window.location.pathname.includes('/kategoria/') || window.location.pathname.includes('/avainsana/')) {
                promoPosition = '-kategoria'
              }
              window.location.href = 'https://tilaus.rakennusmaailma.fi/tilaus?promoID=OM_tilaa-painike&promoName=OM_RM&promoCreative=onlinetarjous&promoPosition=header' + promoPosition
              e.preventDefault()
              return false
            }}
            styleName="top-navigation-item"><span>Tilaa lehti</span></NavLink>
          <div styleName="social-links">
            <SocialLinks />
          </div>
          <div styleName="top-navigation-item">
            {loggedIn
              ? <Link id="login-button" to={{ link: '/tili' }} styleName="login-button"><UserIcon/> <span>Oma tili</span></Link>
              : <a id="login-button" styleName="login-button" href={WP.getLoginUrl()}>
                <span>Kirjaudu</span>
              </a>
            }
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withErrorBoundary(
  NavigationTop,
  ErrorPlaceholder(null)
)
