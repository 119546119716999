import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'
import galleryLogic from '@otavamedia/om-component-library/lib/kea/gallery'
import { getEnv } from '@otavamedia/om-component-library/lib/util/env'
import { IMAGE_SIZE, IMAGE_SIZE_MAP } from '@otavamedia/om-component-library/lib/entities/ImageModel'


const getSrcBySize = (imageModel, size) => {
  if (!imageModel) return false

  const { sizes, src } = imageModel
  const isSVG = src.indexOf('.svg') !== -1
  let sizeSrc = null

  if (size && !isSVG && sizes && sizes[size]) {
    sizeSrc = sizes[size].source_url
  } else if (size && sizes && !sizes[size]) {
    // use a larger image, if possible
    let idx = IMAGE_SIZE_MAP.indexOf(size) + 1
    while (idx <= IMAGE_SIZE_MAP.length) {
      if (size && sizes && sizes[IMAGE_SIZE_MAP[idx]]) {
        sizeSrc = sizes[IMAGE_SIZE_MAP[idx]].source_url
        break
      }
      idx++
    }
    if (!sizeSrc) {
      console.warn(`Media does not exist at specified size (${size}) or any larger size`, imageModel)
    }
  }

  return sizeSrc || src
}

export default @connect({
  actions: [
    galleryLogic, [
      'addImage',
      'removeImage',
    ],
  ],
})
class Image extends Component {
  static propTypes = {
    data: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.bool,
    ]),
    size: PropTypes.string,
    sizes: PropTypes.string,
    isZoomed: PropTypes.bool,
    noSrcSet: PropTypes.bool,
    withCaption: PropTypes.bool,
    addToGallery: PropTypes.bool,
    actions: PropTypes.object,
    divStyle: PropTypes.any,
  }

  state = {}

  addToGallery (data) {
    const { addImage } = this.actions

    if (data) {
      addImage(data, true)
    }
  }

  removeFromGallery () {
    if (this.props.data) {
      this.actions.removeImage(this.props.data, true)
    }
  }

  setAspectRatio (props) {
    const sizes = props.data ? props.data.sizes : null
    if (sizes && sizes.medium) {
      this.setState({ aspect: sizes.medium.height ? sizes.medium.width / sizes.medium.height : 0 })
    }
  }

  componentDidMount () {
    if (this.props.addToGallery) {
      this.addToGallery(this.props.data)
    }
    this.setAspectRatio(this.props)
  }

  componentWillReceiveProps (newProps) {
    if (newProps.addToGallery) {
      if (this.props.data && newProps.data && this.props.data.link !== newProps.data.link) {
        this.removeFromGallery()
      }
      this.addToGallery(newProps.data)
    }
    this.setAspectRatio(newProps)
  }

  componentWillUnmount () {
    const { removeImage } = this.actions
    const { data } = this.props

    if (data) {
      removeImage(data, true)
    }
  }

  render () {
    const {
      data: imageModel,
      size = IMAGE_SIZE.MEDIUM,
      noSrcSet,
      withCaption,
      actions: discard,
      addToGallery,
      sizes,
      isZoomed,
      divStyle,
      ...remaining
    } = this.props
    const {
      aspect
    } = this.state
    const portraitSizes = aspect ? '(max-width: 1440px) ' + aspect * 100 + 'vh, 1440px' : '(max-width: 1440px) 100vw, 1440px'

    if (!imageModel || !imageModel.src) {
      return false
    } else if (typeof imageModel === 'number') {
      if (imageModel !== 0) {
        // WP returns 0 in case there's no image
        console.error('Image component only accepts image objects, number passed')
      }
      return false
    }

    const { srcSet, alt, mediaType, focus } = imageModel
    const src = mediaType === 'video' ? imageModel.src : getSrcBySize(imageModel, size)

    const condProps = { alt }
    if (addToGallery) {
      remaining.className = remaining.className ? remaining.className + ' gallery-image' : 'gallery-image'
    }

    if (alt.length === 0) {
      condProps.alt = ''
      condProps.role = 'presentation'
    }

    if (srcSet) {
      condProps.srcSet = srcSet

      if (getEnv() !== 'production' && !imageModel.sizes) {
        delete condProps.srcSet
        console.warn(`<Image> had srcSet, but no sizes attribute.
  Removing srcSet to avoid loading the largest possible image.
  Add sizes attribute to suppress this warning, or add prop 'noSrcSet={true}' to the <Image> to disable srcSet entirely.`)
      }
    }

    if (noSrcSet) {
      delete condProps.srcSet
    }

    // remove dispatch prop provided by kea
    delete remaining.dispatch

    if (mediaType === 'video') {
      return <div className={divStyle} dangerouslySetInnerHTML={{
          __html: `
          <video
            loop
            muted
            autoplay
            playsinline
            src="${src}"
          />`
        }}>
        </div>
    }

    if (focus) {
      remaining.style = { objectPosition: focus[0] + '% ' + focus[1] + '%' }
    }
    if (withCaption && imageModel.caption) {
      const { figureClassName, ...rest } = remaining
      const { caption, copyright } = imageModel

      // eslint-disable-next-line jsx-a11y/alt-text
      const img = <img loading="lazy" src={src} sizes={isZoomed && (aspect > 1) ? portraitSizes : sizes} {...condProps} {...rest} />

      return (
        <figure {...{ className: figureClassName }}>
                      {img}
          <figcaption>
            {caption}
          </figcaption>

          {copyright && (
            <div className="copyright">{copyright}</div>)
          }
        </figure>
      )
    }

    { /* eslint-disable-next-line jsx-a11y/alt-text */ }
    return <img src={src} loading="lazy" sizes={isZoomed && (aspect > 1) ? portraitSizes : sizes} {...condProps} {...remaining} />
  }
}
