import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import MagazineCarouselLoader from '../general/util/MagazineCarouselLoader'
import StyledDigiMagazineCardCarousel from '../weeklyMagazine/StyledDigiMagazineCarousel'
import { CaretLeft, CaretRight } from '../widgets/Icons'

import WP from '@otavamedia/om-component-library/lib/lib/WP'

import './MagazineCarouselRow.scss'
import isEqual from 'lodash/isEqual'

class DigiMagazineDecadeLoader extends Component {
  _mounted = false
  asyncSetState (...args) {
    // avoid setting state after unmount in case async operations attempts to do so
    if (this._mounted) this.setState(...args)
  }

  constructor (props) {
    super(props)
    this.state = {
      magazines: [],
      loading: null,
    }
  }

  async loadMagazines () {
    this.setState({
      loading: true,
    })
    const magazines = await WP.getMagazineYear(this.props.year)

    this.asyncSetState({
      magazines: magazines.map((mag, i) => {
        mag.className = ''
        if ((i === 0 || parseInt(mag.name) !== parseInt(magazines[i - 1].name))) {
          mag.className += ' left-card'
        }
        if (i === magazines.length - 1 || parseInt(mag.name) !== parseInt(magazines[i + 1].name)) {
          mag.className += ' right-card'
        }
        return mag
      }),
      loading: false,
    }, () => this.props.onLoad(this.state))
  }

  componentDidMount () {
    this._mounted = true
    this.loadMagazines()
  }

  componentWillUnmount () {
    this._mounted = false
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.year !== this.props.year) {
      this.loadMagazines()
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(this.state, nextState) || !isEqual(this.props, nextProps)
  }

  render () {
    const carouselClass = []

    if (this.props.highlightSelected) carouselClass.push('highlight-selected')

    return this.state.loading !== false
      ? <MagazineCarouselLoader/>
      : <StyledDigiMagazineCardCarousel className={carouselClass.join(' ')} initialSlide={this.props.initialSlide}
        onItemClick={this.props.onMagazineItemClick} magazines={this.state.magazines} arrowLeft={<CaretLeft color="red"/>}
        arrowRight={<CaretRight color="red"/>}/>
  }
}

DigiMagazineDecadeLoader.propTypes = {
  year: PropTypes.number.isRequired,
  onLoad: PropTypes.func,
  onMagazineItemClick: PropTypes.func,
  initialSlide: PropTypes.number,
  highlightSelected: PropTypes.bool,
}

export default withErrorBoundary(
  DigiMagazineDecadeLoader,
  ErrorPlaceholder(null)
)
