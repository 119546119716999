import React from 'react'
import PropTypes from 'prop-types'
import './MagazineLabel.scss'
import { SiteLogo } from './Icons'

export const MagazineLabel = ({ text }) => {
  return (
    <div styleName="magazine-label">
      <span styleName="logo"><SiteLogo /></span>
      <span styleName="text">{text}</span>
    </div>
  )
}

MagazineLabel.propTypes = {
  text: PropTypes.string,
}
