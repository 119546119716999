import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'

import CustomForm from '../comments/CustomForm'
import ProductComment from '../comments/ProductComment'
import galleryLogic from '@otavamedia/om-component-library/lib/kea/gallery'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import WP from '@otavamedia/om-component-library/lib/lib/WP'

import './Question.scss'

@connect({
  actions: [
    galleryLogic, [
      'openGallery',
    ],
  ],
  props: [
    auth, [
      'loggedIn',
      'username'
    ]
  ]
})
  /**
   * Displays a question card (kyselykortti) with responses, and a form for readers to submit their own responses.
   * Shortcode parameter means card in placed in the article text using a short code.
   */
class Question extends Component {
  static propTypes = {
    card: PropTypes.object,
    loggedIn: PropTypes.bool,
    isMemory: PropTypes.bool,
    username: PropTypes.string,
    shortCode: PropTypes.bool,
  }

  state = {
    open: false,
    okToSend: false,
    grade: 3,
    isWriting: false,
    selectedFile: null,
    comment: '',
    comments: [],
    readerGrade: 0,
    waitingApproval: false,
    page: 0,
    quote: null,
    admins: []
  }

  async loadComments (props) {
    WP.getAuthors().then((admins) => this.setState({ admins }))
    let { data: comments } = await WP.getComments(this.props.card.id)
    comments
      .forEach((comment, idx) => {
        if (comment.parent) {
          const parent = comments.find(c => c.id === comment.parent)
          if (parent) {
            parent.reply = comment
            delete comments[idx]
          }
        }
      })
    comments = comments.filter(x => x) // remove undefined elements
    this.setState({ comments })
  }

  componentDidMount () {
    this.loadComments(this.props)
  }

  componentWillReceiveProps (newProps) {
    if (newProps.id !== this.props.card.id) {
      this.loadComments(newProps)
    }
  }

  updateComment (newComment) {
    const idx = this.state.comments.findIndex((comment) => comment.id === newComment.id)
    if (idx >= 0) {
      const comments = this.state.comments
      comments[idx].acf = newComment.acf
      this.setState({ comments })
    }
  }

  quote (comment) {
    this.setState({ quote: comment })
  }

  render () {
    const { card, shortCode } = this.props
    const { comments, page, quote, admins } = this.state
    const {
      commentPlaceholder,
      commentTitle,
      header,
      instructions,
      showImage,
      showStars,
      showTitle,
      thankYouText,
      thankYouTitle,
      titleTitle,
      anonTitle,
      anonText,
      buttonText
    } = card
    if (!card || (card.shortCode && !shortCode)) {
      return null
    }
    const perPage = 10
    const shownComments = comments.slice(page * perPage, (page + 1) * perPage)

    return (
      <div className="memories" styleName="main">
        {comments.length
          ? <div styleName="header">
            <div styleName="title">{card.title}</div>
          </div>
          : null}
        {shownComments
          ? <div styleName="comments">
            {shownComments.map((comment, idx) =>
              <ProductComment
                key={comment.id}
                comment={comment}
                isLast={idx === comments.length - 1}
                admins={admins}
                hideActions={true}
                quote={(comment) => this.quote(comment)}
                setComment={(comment) => this.updateComment(comment)}
                showStars={showStars}
                showTitle={showTitle}
                showImage={showImage}
              />
            )}
          </div>
          : null}
        <CustomForm
          quote={quote}
          anonTitle={anonTitle}
          anonText={anonText}
          buttonText={buttonText}
          id={card.id}
          commentPlaceholder={commentPlaceholder}
          commentTitle={commentTitle}
          header={header}
          instructions={instructions}
          showImage={showImage}
          showStars={showStars}
          showTitle={showTitle}
          thankYouText={thankYouText}
          thankYouTitle={thankYouTitle}
          titleTitle={titleTitle}
          cancel={() => this.setState({ showForm: false })}/>
      </div>
    )
  }
}

export default Question
