import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'kea'
import isEqual from 'lodash/isEqual'
import trimEnd from 'lodash/trimEnd'
import debounceRender from 'react-debounce-render'
import track from 'react-tracking'
import { ThemeProvider } from 'styled-components'
// import CookieConsent from 'react-cookie-consent'

import { is as errorIs, getType,  logErrorBoundaryError } from '@otavamedia/om-component-library/lib/lib/errors'
import RenderedError from '../components/general/util/RenderedError'
import application from '@otavamedia/om-component-library/lib/kea/application'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import headerLogic from '@otavamedia/om-component-library/lib/kea/header'
import magazineStore from '@otavamedia/om-component-library/lib/kea/weeklyMagazine'
import galleryLogic from '@otavamedia/om-component-library/lib/kea/gallery'
import adsLogic from '@otavamedia/om-component-library/lib/kea/ads'
import Placeholder from './Placeholder'
import FrontPage from './FrontPage'
import Resolver from './Resolver'
import Taxonomy from './Taxonomy'
import Article from './Article'
import OrderConfirmation from './OrderConfirmation'
import BestProductCollection from './BestProductCollection'
import CardCollection from './CardCollection'
import UserReviewCollection from './UserReviewCollection'
import TestBankCategory from './TestBankCategory'
import TestBank from './TestBank'
import Feedback from './Feedback'
import MagazineArchive from '../components/weeklyMagazine/MagazineArchive'
import Loading from '../components/general/util/Loading'
import AdminBar from '../components/adminbar/AdminBar'
import SettingsComponent from '../components/Settings'
import Header from '../components/views/Header'
import Footer from '../components/views/Footer'
import Search from '../components/general/search/Search'
import RemoteLogin from '../components/general/util/RemoteLogin'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import renderDebugger from '@otavamedia/om-component-library/lib/lib/debug-render'
import Gallery from '../components/gallery/Gallery'
import MagazineArchiveHighlightsPage from '../components/archive/MagazineArchiveHighlightsPage'
import DigiMagazineArchive from '../components/weeklyMagazine/DigiMagazineArchive'
import ThemeMagazineArchive from '../components/weeklyMagazine/ThemeMagazineArchive'
import './App.scss'
import { getCookie } from '@otavamedia/om-component-library/lib/lib/utils'
import Videos from './Videos'
import User from './User'
import WeeklyMagazine from '../components/weeklyMagazine/WeeklyMagazine'
import MagResolver from './MagResolver'
import Author from './Author'
import Latest from './Latest'
import { isAdministratorLikeUser } from '@otavamedia/om-component-library/lib/lib/userManagement'
import UserCards from './UserCards'
import Offer from './Offer'
import AdManager from '../components/general/ads/AdManager'
import { isHeadless, getEnv } from '@otavamedia/om-component-library/lib/util/env'

// SASS variables; If you change these, also remember to change settings.scss/colours.scss
const theme = {
  contentWidth: '1440px',
  footerHeight: '540px',
  mobileNavHeight: '4rem',
  tabletNavHeight: '8.5rem',
  tabletNavHeightCollapsed: '4rem',
  bodyFontSize: '18',
  bodyLineHeight: '30',

  colourPrimary: '#00ab4e',
  colourPrimaryLight: 'rgb(255, 255, 255)',
  colourPrimaryDarker: '#039831',
  colourSecondary: '#fff93d',

  colourVeryDark: 'rgb(0, 0, 0)',
  colourDark: 'rgb(0, 9, 30)',
  colourLightgrey: '#f2f2f4',
  colourGrey: '#979797',
  colourDarkgrey: '#484e5d',

  colourLink: '#1ab04d',
  colourLinkVisited: '#039831',

  colourArticleBorder: '#d9dadc',
  colourHeaderBackground: '#ededee',
  colourLightgreyBackground: '#fafafb',
  colourInputBg: '#fafafb',
  colourTitleHover: '#565e70',
  colourTvtmMeta: '#666',
  colourBwHover: '#c4c4c4',
  imageCaptionBackground: 'rgba(0, 0, 0, 0.5)',
  tableMainHeader: '#90ACC6',
  tableSubHeader: '#e9f0f7',
  tableBorder: '#E6E6E8',
  tableOddRow: '#FAFAFB',
  sidestoryBackground: '#fafafb',
  errorBackground: 'rgba(231, 3, 33, 0.06)',
  fieldsetBackground: 'rgba(0, 0, 0, 0.02)',
  colourCommentBorder: '#d8d8d8',
  colourPowerOrange: '#ec6608',
  colourTopPicksBg: 'rgba(17, 29, 56, 0.75)',
}

@track({}, {
  dispatch: data => {
    const navData = { ...data }
    navData.targetElementPath = data.gtmContext
    delete navData.gtmContext
    delete navData.element
    delete navData.page_subtype
    navData.targetElement = navData.targetElementPath && navData.targetElementPath.length ? navData.targetElementPath[navData.targetElementPath.length - 1] : null
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(navData)

    if (data.element) {
      data.event = 'element_click'
      data.location = data.gtmContext && data.gtmContext.length ? data.gtmContext[data.gtmContext.length - 1] : null
      delete data.gtmContext
      delete data.targetElement
      delete data.gtmContext
      window.dataLayer.push(data)
    }
  }
})
@connect({
  actions: [
    application, [
      'setError',
      'setRendered',
      'updateSettings',
      'start',
    ],
    auth, [
      'startAuth'
    ],
    adsLogic, [
      'setupAds'
    ],
  ],
  props: [
    magazineStore, [
      'contents as digimag',
    ],
    auth, [
      'userRoles',
      'loggedIn',
      'user',
      'premiumUser',
    ],
    application, [
      'resolverStatus',
      'ready as appReady',
      'contentTypes',
      'isArticleView',
      'isMagazineView',
      'isMagazineArticleView',
      'isFeatureArticle',
      'settings',
      'pathname'
    ],
    headerLogic, [
      'isSecondLevelNavOpen',
      'ready as headerReady',
      'menus'
    ],
    galleryLogic, [
      'galleryOpen',
    ],
  ],
})
@renderDebugger
class App extends Component {
  constructor (props) {
    super(props)

    this.state = {
      routes: {},
      error: false,
    }

    if (this.getCookieValue('om_login_greeting') === 'true') {
      this.state.showGreeting = true
      const site = getEnv() === 'development' ? 'rakennusmaailma.fi' : 'rakennusmaailma.asteaws.dev'
      document.cookie = "om_login_greeting=;expires=Thu, 01 Jan 1970 00:00:00 UTC;Secure=true;SameSite=None;domain=" + site
    }
  }

  static propTypes = {
    updateSettings: PropTypes.func,
    settings: PropTypes.object,
    userRoles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    isSecondLevelNavOpen: PropTypes.bool,
    isArticleView: PropTypes.bool,
    isMagazineView: PropTypes.bool,
    isFeatureArticle: PropTypes.bool,
    isMagazineArticleView: PropTypes.bool,
    headless: PropTypes.bool,
    resolverStatus: PropTypes.number,
    appReady: PropTypes.bool,
    userReady: PropTypes.bool,
    headerReady: PropTypes.bool,
    menus: PropTypes.array,
    contentTypes: PropTypes.object,
    history: PropTypes.object,
    galleryOpen: PropTypes.bool,
    premiumUser: PropTypes.bool,
    rendered: PropTypes.bool,
    digimag: PropTypes.object,
    location: PropTypes.object,
    loggedIn: PropTypes.bool,
    updating: PropTypes.bool, // SW caches the app locally, this is true when it's updating
  }

  static defaultProps = {
    appReady: false,
  }

  getCookieValue (name) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
    return match ? match[2] : null
  };


  shouldComponentUpdate (nextProps, nextState) {
    if (isEqual(nextProps, this.props) && isEqual(nextState, this.state)) {
      return false
    }

    return true
  }

  /**
   * Error handler that intercepts errors and acts accordingly
   * Errors are returned to the catch block of the requesting function.
   */
  applicationErrorHandler = (error) => {
    const type = getType(error.message)
    const { setError } = this.actions
    let broadcastError = true

    if (errorIs.severe(type)) {
      // Setting the error property in the state prevents anything other than the error from showning.

      if (type === 'INIT_ERROR') {
        this.setState({ error })
        broadcastError = false
      }
    } else if (errorIs.significant(type)) {
      console.error('Significant error', error)
    } else if (errorIs.minor(type)) {
      console.error('Minor error', error)
      broadcastError = false
    }

    if (broadcastError) {
      // Add the error to state so it's accessible to every component
      setError(error)
    }

    // Return the error to the catch block
    return error
  }

  generateRouteComponents () {
    const { setErrorState, applicationErrorHandler } = this
    const { setRendered } = this.actions
    const additionalProps = {
      setErrorState,
      applicationErrorHandler,
      setRendered,
    }

    this.setState({
      routes: {
        remoteLogin: (props) => <RemoteLogin {...additionalProps} {...props} />,
        resolver: (props) => <Resolver {...additionalProps} {...props} />,
        magResolver: (props) => <MagResolver {...additionalProps} {...props} />,
        index: (props) => <FrontPage {...additionalProps} {...props} />,
        author: (props) => <Author {...additionalProps} {...props} doneLoading={(value) => setRendered(value)} />,
        article: (props) => <Article {...additionalProps} {...props} doneLoading={(value) => setRendered(value)} />,
        search: (props) => <Search {...additionalProps} {...props} />,
        videos: (props) => <Videos {...additionalProps} {...props} />,
        latest: (props) => <Latest {...additionalProps} {...props} />,
        offer: (props) => <Offer {...additionalProps} {...props} doneLoading={(value) => setRendered(value)}/>,
        mostRead: (props) => <Latest {...additionalProps} {...props} mostRead/>,
        digiMagArchive: (props) => <DigiMagazineArchive {...props} />,
        themeMagazines: (props) => <ThemeMagazineArchive {...props} />,
        latestMagazine: () => {
          // redirect to the actual latest magazine url
          const { menus } = this.props
          const magazineMenu = menus.find(menu => menu.taxonomy === 'printmag')
          return magazineMenu ? <Redirect to={magazineMenu.url}/> : null
        },
        weeklyMagazine: (props) => <WeeklyMagazine {...additionalProps} {...props} />,
        themeMagazine: (props) => <WeeklyMagazine {...additionalProps} {...props} isThemeMagazine={true} />,
        adMagazine: (props) => <WeeklyMagazine {...additionalProps} {...props} isAdMagazine={true} />,
        magazineArchive: (props) => <MagazineArchive {...additionalProps} {...props} />,
        magazineArchiveHighlights: (props) => <MagazineArchiveHighlightsPage {...additionalProps} {...props} />,
        feedback: (props) => {
          return <Redirect to="/anna-palautetta" /> // <Feedback {...additionalProps} {...props} form="mvp-palaute"/>
        },
        feedback2: (props) => {
          return <Feedback {...additionalProps} {...props} form="mvp-palaute-2"/>
        },
        keskustelu: () => (
          // Dirty side effect, but necessaryish.
          window.location.href = 'https://keskustelu.tekniikanmaailma.fi'
        ),
        digilehti: () => (
          <Redirect to="/lehti" />
        ),
        order: () => {
          window.location.href = 'https://tilaus.rakennusmaailma.fi/tilaus'
          return null
        },
        tests: () => (
          <Redirect to="/testit/" />
        ),
        testWinners: () => (
          <Redirect to="/testivoittajat/" />
        ),
        settings: () => (
          <Placeholder>
            <SettingsComponent settings={this.props.settings} updateSettings={this.actions.updateSettings}/>
          </Placeholder>
        ),
        user: () => <User/>,
        bestproducts: (props) => {
          return <BestProductCollection {...additionalProps} {...props} />
        },
        questionCards: (props) => {
          return <CardCollection {...additionalProps} {...props} postType={'om_question'} page={'lukijoiden-vinkit'} />
        },
        memoryCards: (props) => {
          return <CardCollection {...additionalProps} {...props} postType={'om_product_memory'} page={'lukijoiden-muistot'} />
        },
        userReviews: (props) => {
          return <UserReviewCollection {...additionalProps} {...props} />
        },
        userReviewCards: (props) => {
          return <CardCollection {...additionalProps} {...props} postType={'om_product'} page={'kayttajaarvostelut/autot-ja-moottoripyorat'}
            mainCategory={'autot'} customSort={{ comment_count: 'Kommentoiduimmat' }}/>
        },
        userReviewElectronic: (props) => {
          return <CardCollection {...additionalProps} {...props} postType={'om_product'} page={'kayttajaarvostelut/elektroniikka-ja-teknologia'}
            mainCategory={'teknologia'} customSort={{ comment_count: 'Kommentoiduimmat' }}/>
        },
        userCards: (props) => {
          return <UserCards {...additionalProps} {...props} />
        },
        testbank: (props) => {
          return <TestBank {...additionalProps} {...props} />
        },
        testbankCategory: (props) => {
          return <TestBankCategory {...additionalProps} {...props} />
        },
        orderConfirmation: props => <OrderConfirmation {...additionalProps} {...props} />,
      }
    })
  }

  async componentDidMount () {
    WP.setErrorHandlerService(this.applicationErrorHandler)

    this.actions.start()
    if (!isHeadless()) {
      AdManager.init()
    }

    this.actions.startAuth()
    this.generateRouteComponents()

    const trackingConsent = getCookie('CookieConsent')
    if (trackingConsent && trackingConsent.value) {
      this.startTracking()
    }
    if (window.dataLayer) {
      await window.dataLayer.push({ event: 'optimize.activate' })
    }
  }

  componentWillUnmount () {
    WP.setErrorHandlerService(null)
  }

  componentDidCatch (error, info) {
    this.setState({ error })
    logErrorBoundaryError(error, info)
  }

  taxOrPostTypePrimaryRoute ([slug, obj]) {
    return (
      <Route path={`/${obj.url_base}`} exact key={slug} component={Taxonomy} />
    )
  }

  taxOrPostTypeRoute ([slug, obj]) {
    return (
      <Route path={`/${obj.url_base}`} key={slug} component={Taxonomy} />
    )
  }

  entryPointContextClasses = () => {
    const { isSecondLevelNavOpen, isMagazineView, isMagazineArticleView, digimag, isFeatureArticle, pathname } = this.props
    const classes = []
    const magazineExists = digimag && Object.keys(digimag).length

    if (isSecondLevelNavOpen) classes.push('second-level-nav')
    if (isMagazineView) classes.push('weekly-magazine')
    if (isMagazineArticleView && magazineExists) classes.push('weekly-magazine-article')
    if (isFeatureArticle) classes.push('feature-article')

    return classes
  }

  startTracking = () => {
  }

  render () {
    const { error, routes, showGreeting } = this.state
    const {
      contentTypes,
      galleryOpen,
      headerReady,
      appReady,
      history,
      updating,
      location,
      loggedIn,
      userRoles,
      // isFeatureArticle,
    } = this.props
    const { postTypes, taxonomies } = contentTypes || {}

    if (!this.props.menus.length && appReady) {
      console.log('reports ready too early', this.props.menus)
    }

    if (updating) {
      const text = <p>Sivustoon on julkaistu päivitys viime käyntisi jälkeen, odota hetki kun lataamme sen.</p>

      return (
        <ThemeProvider theme={theme}>
          <Fragment>
            <Loading ready={updating} className="fullscreen" loadingText={text}>
              <p>Valmista tuli! Päivitä sivu jos tämä viesti ei häviä itsestään.</p>
            </Loading>
          </Fragment>
        </ThemeProvider>
      )
    }

    const entryPointClasses = ['app-entry-point', 'main-container'].concat(this.entryPointContextClasses())
    // const trackingConsent = getCookie('CookieConsent')

    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <a className="skip-link screen-reader-text" href="#content">Siirry sisältöön</a>
          {/* isFeatureArticle ? null : */ <Loading ready={headerReady} className="hidden">
            <Header pathname={this.props.location.pathname} />
          </Loading>}

          <Loading ready={appReady} className="fullscreen">
            <Fragment>
              <div styleName={entryPointClasses.join(' ')} data-path={trimEnd(history.location.pathname, '/')}>

                <div styleName="content-row" id="content">
                  { error
                    ? (
                      <div styleName="applicationError">
                        <RenderedError error={error} />
                      </div>
                    )
                    : (
                      <Fragment>
                        <Switch>
                          <Route exact={true} path="/kategoria/testivoittaja/" component={routes.testWinners} />
                          <Route exact={true} path="/kategoria/testit/" component={routes.tests} />
                          <Route exact={true} path="/avainsana/testipankki/" component={routes.tests} />
                          {postTypes && Object.entries(postTypes).map(this.taxOrPostTypePrimaryRoute)}
                          {postTypes && Object.entries(postTypes).map(this.taxOrPostTypeRoute)}

                          {taxonomies && Object.entries(taxonomies).map(this.taxOrPostTypePrimaryRoute)}
                          {taxonomies && Object.entries(taxonomies).map(this.taxOrPostTypeRoute)}

                          <Route exact={true} path="/" component={routes.index} />
                          <Route exact={true} path="/haku/" component={routes.search} />
                          <Route path="/p" component={routes.article} />
                          <Route path="/keskustelu" component={routes.keskustelu} />
                          <Route path="/tarjous" component={routes.offer}/>
                          <Route path="/digilehti" component={routes.digilehti}/>
                          <Route path="/lehti/:number/:article" component={routes.magResolver}/>
                          <Route path="/lehti/:number" component={routes.weeklyMagazine}/>
                          <Route path="/lehti" component={routes.latestMagazine}/>
                          <Route path="/teemalehti/:number/:article" component={routes.magResolver}/>
                          <Route path="/teemalehti/:number" component={routes.themeMagazine}/>
                          <Route path="/digilehdet" component={routes.digiMagArchive}/>
                          <Route path="/teemalehdet" component={routes.themeMagazines}/>
                          <Route path="/lukijoilta" component={routes.userCards}/>
                          <Route path="/kayttajaarvostelut/autot-ja-moottoripyorat/:category" component={routes.userReviewCards} />
                          <Route path="/kayttajaarvostelut/autot-ja-moottoripyorat" component={routes.userReviewCards} />
                          <Route path="/kayttajaarvostelut/elektroniikka-ja-teknologia/:category" component={routes.userReviewElectronic} />
                          <Route path="/kayttajaarvostelut/elektroniikka-ja-teknologia" component={routes.userReviewElectronic} />
                          <Route path="/kayttajaarvostelut" component={routes.userReviews} />
                          <Route path="/lukijoiden-vinkit/:category" component={routes.questionCards} />
                          <Route path="/lukijoiden-vinkit" component={routes.questionCards} />
                          <Route path="/lukijoiden-muistot/:category" component={routes.memoryCards} />
                          <Route path="/lukijoiden-muistot" component={routes.memoryCards} />
                          <Route path="/uusimmat/:category?" component={routes.latest}/>
                          <Route path="/suosituimmat/:category?" component={routes.mostRead}/>
                          <Route path="/tilaa/" component={routes.order}/>

                          <Route path="/mainoslehti/:number/:article" component={routes.magResolver}/>
                          <Route path="/mainoslehti/:number" component={routes.adMagazine}/>
                          <Route path="/toimittaja/:name" component={routes.author}/>

                          <Route path="/arkisto/aarteita/:number" component={routes.magazineArchiveHighlights}/>
                          <Route path="/arkisto/aarteita" component={routes.magazineArchiveHighlights}/>
                          <Route path="/arkisto/:number" component={routes.magazineArchive}/>
                          <Route path="/arkisto" component={routes.magazineArchive}/>
                          <Route path="/videot" component={routes.videos}/>
                          <Route path="/palaute" component={routes.feedback}/>
                          <Route path="/anna-palautetta" component={routes.feedback2}/>
                          <Route path="/tili" component={routes.user}/>
                          <Route path="/asetukset" component={routes.settings}/>
                          <Route path="/testivoittajat/:category" component={routes.bestproducts} />
                          <Route path="/testivoittajat" component={routes.bestproducts} />
                          <Route path="/testit-:category/:subcategory?" component={routes.testbankCategory} />
                          <Route exact={true} path="/testit/" component={routes.testbank} />
                          <Route path="/kiitos-tilauksestasi" component={routes.orderConfirmation} />
                          <Route path="/remote-login/" component={routes.remoteLogin} />
                          <Route path={'/'} component={routes.resolver}/>
                        </Switch>
                      </Fragment>
                    ) }
                </div>
              </div>
              <Footer />
              {loggedIn && isAdministratorLikeUser(userRoles) ? <AdminBar location={location}/> : null}
              {galleryOpen && <Gallery/>}
            </Fragment>
          </Loading>
          {showGreeting && <div styleName="om-login-greeting">
            <h3>Tervetuloa!</h3>
            <p>
              Olet nyt kirjautuneena sisään.
            </p>
            <button aria-label="Sulje ilmoitus">
              <span aria-hidden="true">x</span>
            </button>
          </div>}
        </Fragment>
      </ThemeProvider>
    )
  }
}

export default debounceRender(App)
