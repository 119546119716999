import React, { Component } from 'react'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import '../../containers/Article.scss'
import PropTypes from 'prop-types'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import ArticleHTML from '../general/util/ArticleHTML'

/**
 * Displays the /tarjous page. The main image is fetched from a page in WP, the rest of the content is static.
 */
export default class ArticleContent extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
  }

  constructor (props) {
    super(props)
    this.state = { view: null }
    this.loadPage()
  }

  async loadPage () {
    const page = await WP.getForURL(this.props.url)
    this.setState({ view: page.data })
  }

  render () {
    const {
      view: article
    } = this.state

    if (!article) {
      return null
    }

    const {
      id,
      createToc,
      content,
      title
    } = article

    return <article styleName="article">
      <div>
        <h2 styleName="black-title"><HTML>{title}</HTML></h2>
      </div>
      <div styleName="article-text list-view" className={'article-body noAds'}>
        <ArticleHTML options={{ id, createToc, filterDivs: true }}>{content}</ArticleHTML>
      </div>
    </article>
  }
}
