import React from 'react'
import styles from './ErrorBoundaries.scss'
import ErrorBoundary from 'react-error-boundary'
import classnames from 'classnames'
import { isFunction } from 'lodash/lang'
import { logErrorBoundaryError } from '@otavamedia/om-component-library/lib/lib/errors'
import RenderedError from './RenderedError'

const STYLE_NONE = 'style-none'
const STYLE_BOX = 'style-box'
const defaultOptions = { style: STYLE_NONE, minHeight: 200 }

/**
 * Component to render fallback placeholder when error boundary catches error.
 * @param content
 * Content can be anything, including function that will receive props.
 * The props will contain:
 * componentStack: string
 * error: object
 * erroredComponentProps: object
 * @param options
 * @returns {Component}
 */
export const ErrorPlaceholder = (content = 'Tapahtui virhe', options = defaultOptions) => {
  options = { ...defaultOptions, ...options }
  return React.createFactory((props) => {
    const error = <RenderedError error={props.error} alternative={isFunction(content) ? content(props) : content} />

    return (
      <div
        styleName='error-boundary'
        className={classnames(styles[options.style])}
        style={ { minHeight: parseFloat(options.minHeight) + 'px' } }
      >
        <div styleName="content">{error}</div>
      </div>
    )
  })
}

/**
 * Error fallback placeholder component with "box" style.
 */
export const ErrorPlaceholderBox = (content, options = {}) => {
  options = { ...options, style: STYLE_BOX }
  return ErrorPlaceholder(content, options)
}

/**
 * Util function to pass errored component's props to fallback component.
 */
export const fallbackComponentWithProps = (FallbackComponent, componentProps) => {
  return React.createFactory((props) => {
    return (
      <FallbackComponent {...props} erroredComponentProps={componentProps} />
    )
  })
}

/**
 * ErrorBoundary HoC to wrap any component to catch any errors occurring within wrapped component.
 * This is basically the same as react-error-boundary component's withErrorBoundary, with the exception
 * that we are passing props of errored component to the fallback component.
 * @param Component
 * @param FallbackComponent
 * @param onError
 * @returns {Component}
 */
export const withErrorBoundary = (Component, FallbackComponent) => {
  return React.createFactory((props) => {
    return (
      <ErrorBoundary
        FallbackComponent={fallbackComponentWithProps(FallbackComponent, props)}
        onError={logErrorBoundaryError}
      >
        <Component {...props} />
      </ErrorBoundary>
    )
  })
}
