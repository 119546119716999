import styled from 'styled-components'
import MagazinePicks from '@otavamedia/om-component-library/lib/MagazinePicks'

const MagazineHighlight = styled(MagazinePicks)`
  background-color: ${props => props.theme.colourPrimaryLight};

  .header {
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-family: "Flama Medium";
    font-size: 0.875rem;
    line-height: 1rem;
    text-transform: uppercase;
    color: ${props => props.theme.colourPrimaryLight};
    background-color: ${props => props.theme.colourDarkgrey};
  }

  .content {
    border: 1px solid ${props => props.theme.colourArticleBorder};
    padding: 1.5rem 1.5rem 1.5rem 1rem;
  }

  .image {
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .info {
    .page-number {
      font-family: "Guardian TextSans Bold";
      color: red;
    }

    ul {
      padding: 0;
      margin: 0;
    }

    li {
      margin-bottom: 0.5rem;
      font-size: 0.875rem;
    }
  }

  .head {
    display: flex;
    align-items: baseline;

    .title {
      flex: 1 1 100%;
      font-family: "Flama Semicond Semibold";
      font-size: 1.25rem;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }

    .page {
      font-family: "Guardian TextSans Bold";
      font-size: 14px;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  .summary {
    font-size: 14px;
  }

  .date {
    display: inline-block;
    width: 100%;
    text-align: right;
    font-family: "Flama";
    font-size: 12px;
  }

  &.error {
    width: 100%;
    background-color: ${props => props.theme.colourPrimaryLight};
    border: 1px solid ${props => props.theme.colourArticleBorder};
    padding: 1.5rem 1.5rem 1.5rem 1rem;
  }
`

export default MagazineHighlight
