import React from 'react'
import PropTypes from 'prop-types'
import missingImage from '../../../assets/pictures/placeholder.svg'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import Image from '../util/Image'

const ArticleThumbnail = ({ article }) => {
  return (article.featuredMedia && article.featuredMedia.src)
    ? <Image data={article.featuredMedia} size={IMAGE_SIZE.THUMBNAIL} sizes={'100px'} />
    : <img src={missingImage} alt="Kuvaa ei löytynyt" />
}

ArticleThumbnail.propTypes = {
  article: PropTypes.object,
}

export default ArticleThumbnail
