import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from '../general/util/Links'
import { connect } from 'kea'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import some from 'lodash/some'

import './OrderCTA.scss'

@connect({
  props: [
    auth, [
      'premiumUser',
    ],
  ],
})
class OrderCTA extends Component {
  static propTypes = {
    categories: PropTypes.array,
    tags: PropTypes.array,
    premiumUser: PropTypes.bool,
  }

  constructor (props) {
    super(props)
    window.dataLayer && window.dataLayer.push({ event: 'OrderCTA.view' })
  }

  render () {
    const { premiumUser, categories, tags } = this.props
    if (premiumUser || some(tags, { slug: 'huumori' })) {
      return null
    }
    const isScience = some(categories, { slug: 'tiede' })
    const text = isScience
      ? window.om_constants.ctaText1
      : window.om_constants.ctaText1
    return <div styleName="main">
      <div styleName="image">
        <img src="https://wp.tekniikanmaailma.fi/wp-content/uploads/s3/sites/3/2022/09/jari_lammassaari_verkko-e1647599966760-1-e1664189333626-300x300.jpg" alt=""/>
      </div>
      <div styleName="title">Jari Lammassaari</div>
      <div styleName="subtitle">Toimituspäällikkö</div>
      <div styleName="text">
        <p><span styleName="cap">Hyvä lukija</span>{text}</p>
        <p styleName="bold">
          Tue vastuullista journalismia. Tilaa {window.om_constants.siteName}.
        </p>
      </div>
      <div styleName="buttons">
        <NavLink onClick={() => window.dataLayer && window.dataLayer.push({ event: 'OrderCTA.click' })} to={{ link: window.om_constants.orderLink }}>Tilaa {window.om_constants.siteName}</NavLink>
      </div>
    </div>
  }
}

export default OrderCTA
