window.om_constants = {
  siteName: 'TM Rakennusmaailma',
  siteNameGen: 'TM Rakennusmaailman',
  aboutSite: 'TM Rakennusmaailmasta',
  toSite: 'TM Rakennusmaailmaan',
  magName: 'TM Rakennusmaailma DIGI',
  magNameGen: 'TM Rakennusmaailman DIGIn',
  archiveHighlights: 'RM testaa - Arkistot kertovat',
  archiveHighlightsDesc: 'Selaile RM:n erikoisnumeroita vuosien varrelta.',
  abbreviation: 'RM',
  paywallHost: 'rakennusmaailma.fi',
  paywallBrandId: 'RM',
  offerId: '149861',
  offerIdDev: '8071',
  appName: 'openid2',
  clientId: '0062f6c3-6e24-4f56-a9e6-b055112d514c',
  clientIdDev: 'f9005cda-5726-4468-a06c-1382b0ef0bb4',
  newsletterText: 'Tilaa TM Rakennusmaailman kerran viikossa lähetettävä uutiskirje. Saat vinkkejä remontointiin, rakentamiseen ja asumiseen sekä uusimmat testimme ja vertailumme!',
  ctaText1: ', talon rakentaminen tai asunnon ostaminen on elämän suurin investointi lähes jokaiselle meistä. ' +
    'Tiesitkö, että talon ylläpitoon tulisi panostaa noin 1500 euroa vuosittain, jotta se pysyisi hyvässä kunnossa? ' +
    'TM Rakennusmaailma tarjoaa sinulle neuvot ja keinot omaisuutesi hoitamiseen.',
  ctaText2: ', luulojen pahin vihollinen on tieto. Juuri nyt tiedon tarve on suurimmillaan ja luuloista on tavallista enemmän haittaa. TM Rakennusmaailma keskittyy tietoon, jotta luuloille ei jäisi mahdollisuuksia. Siinä tarvitsemme sinun apuasi.',
  solrUrl: '/solr/tm/',
  dfpNetworkId: '135812570',
  containerName: 'rakennusmaailma',
  orderLink: '/tilaa/?utm_source=inhouse&utm_medium=painike&utm_campaign=TM_2052',
  facebookLink: 'https://www.facebook.com/tmrakennusmaailma/',
  twitterLink: '',
  feedLink: 'https://rakennusmaailma.fi/feed/',
  instaLink: 'https://www.instagram.com/tm_rakennusmaailma/',
  siteLogo: 'https://rakennusmaailma.fi/assets/logos/site-logo.svg',
  backgroundVideo: 'https://cdn.rakennusmaailma.fi/wp-content/uploads/2019/03/sahkoautojen-talvivertailu.mp4',
  paywallOptimize: 'HxVPkBQkRWGK8F8Xq_jiAA'
}
