import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'

import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import Image from '../util/Image'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import galleryLogic from '@otavamedia/om-component-library/lib/kea/gallery'
import auth from '@otavamedia/om-component-library/lib/kea/auth'

import './GoodBad.scss'
import lockIcon from '../../../assets/ui/lock.png'
import { Link } from '../../general/util/Links'
import WP from '@otavamedia/om-component-library/lib/lib/WP'

@connect({
  actions: [
    galleryLogic, [
      'openGallery',
    ],
  ],
  props: [
    auth, [
      'loggedIn',
      'username',
      'premiumUser'
    ]
  ]
})
  /**
   * Displays the main part of a ProductCard. Also used by Comparisons (which is not used in new articles anymore),
   * that's why this is a separate component.
   */
class GoodBad extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    floated: PropTypes.bool,
    short: PropTypes.bool,
    loggedIn: PropTypes.bool,
    isMemory: PropTypes.bool,
    forSubscribers: PropTypes.bool,
    premiumUser: PropTypes.bool,
    username: PropTypes.string,
    openGallery: PropTypes.func,
  }

  state = {
    open: false,
    okToSend: false,
    grade: 3,
    isWriting: false,
    selectedFile: null,
    comment: '',
    comments: [],
    readerGrade: 0,
    waitingApproval: false
  }

  render () {
    const { data, floated, short, forSubscribers, premiumUser } = this.props
    if (!data || !data.images) {
      return null
    }

    return (
      <Fragment>
        <div styleName="good-bad-table">
          {data.title
            ? <div styleName="image-box">
              {data.images.length
                ? <Image data={data.images[0]} size={IMAGE_SIZE.MEDIUM_LARGE}
                  sizes={'(min-width: 768px) 860px, 100vw'}/>
                : null}
              <div styleName="overlay"></div>
              <div styleName="text-box">
                {data.grade && !short ? <div styleName="grade"><HTML>{data.grade}</HTML></div> : null}
                {data.stars && !short
                  ? <div styleName="stars">{window.om_constants.abbreviation} ARVIO<span styleName="star-icons">{data.stars}</span></div>
                  : <div styleName="stars">&nbsp;</div>}
                <h3><HTML>{data.title}</HTML></h3>
              </div>
            </div>
            : null}
          {forSubscribers && !premiumUser
            ? <Fragment>
              <div styleName="paywall">
                <div styleName="corner">
                  <p>Perushinta</p>
                  <p>Testitul..</p>
                  <div styleName="cover"></div>
                </div>
                <div styleName="content">
                  <img src={lockIcon} alt="" styleName="lock-icon"/>
                  <p>Tilaajana näet<br/>tuotteen tiedot ja {window.om_constants.abbreviation}:n artikkelin.</p>
                  <Link styleName="order" to={{ link: '/tarjous?utm_source=tmfi_lukittu_tuotekortti&utm_medium=lukittu_tuotekortti&utm_campaign=muuritarjous' }}>Tilaan Tekniikan Maailman</Link>
                  <a styleName="subscriber" href={WP.getLoginUrl()}>Olen jo tilaaja</a>
                </div>
              </div>
              {data.goodList && !short
                ? <div styleName="good blur">
                  <h3>Hyvää</h3>
                  <ul>
                    <li>Arvaatko, mitä hyvää tässä tuotteessa on?</li>
                    <li>Et varmaan.</li>
                  </ul>
                </div>
                : null}
              {data.badList && !short
                ? <div styleName="bad blur ">
                  <h3>Huonoa</h3>
                  <ul>
                    <li>Onko tässä tuotteessa huonojakin puolia?</li>
                    <li>Sen saavat tilaajat tietää.</li>
                  </ul>
                </div>
                : null}
            </Fragment>
            : <Fragment>
              {!data.details || short ? null : <div styleName="details-list"><HTML>{data.details}</HTML></div>}
              {data.details && data.bulletPoints && !short ? <hr/> : null}
              {!data.bulletPoints || short ? null : <div styleName="bullet-list"><HTML>{data.bulletPoints}</HTML></div>}
              {!floated && data.images.length
                ? (
                  <Fragment>
                    <hr/>
                    <div styleName="gallery">
                      {data.images.map((image, index) => (
                        <div styleName="image-thumbnail" key={`image-${index}`}>
                          <Image addToGallery data={image} size={IMAGE_SIZE.THUMBNAIL} sizes="(min-width: 1px) 150px"/>
                        </div>
                      ))}
                    </div>
                  </Fragment>
                )
                : null
              }
              {data.goodList && !short
                ? <div styleName="good">
                  <HTML>{data.goodList}</HTML>
                </div>
                : null}
              {data.badList && !short
                ? <div styleName="bad">
                  <HTML>{data.badList}</HTML>
                </div>
                : null}
              {data.byNumbersList && !short
                ? <div styleName="by-numbers">
                  <p>Numeroiden mukaan</p>
                  <HTML>{data.byNumbersList}</HTML>
                </div>
                : null}
            </Fragment>
          }
        </div>
      </Fragment>
    )
  }
}

export default GoodBad
