import styled from 'styled-components'
import MagazineCardCarousel from '@otavamedia/om-component-library/lib/MagazineCardCarousel'

const StyledMagazineCardCarousel = styled(MagazineCardCarousel)`
  padding: 0 0.75rem;

  .Icons_caret-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .items-carousel {
    .nav-button-prev {
      flex: 0 0 40px;
      background-color: rgba(255, 255, 255, 0.8);
      margin-right: 0;
    }
    .nav-button-next {
      flex: 0 0 40px;
      background-color: rgba(255, 255, 255, 0.8);
      margin-left: 0;
    }
    
    @media (min-width: 1200px) {
      margin-left: rem(38px);
    }
  }
  
  .magazine-card {
    width: 100px;
    height: 165px;
    margin-right: 0;
    
    .name {
      font-family: "Flama Medium", sans-serif;
      font-size: 14px;
    }
    
    @media (min-width: 1000px) {
      width: 225px;
      height: 330px;
    }

    .image {
      display: flex;
      justify-content: center;      
      padding-left: 4px;
      padding-right: 4px;
      
      img {
        width: auto;
        max-height: 280px;
        background: #1ab04d;
      }
    }
  }
  
  &.highlight-selected {
    .slick-slide > div > div {
      opacity: 0.5;
      
      &.selected {
        opacity: 1;
      }
    }
  }

  .slick-slider {
    margin-left: -2.75rem;
  }
  
  .slick-slide {
    margin-right: 1.5rem;
    cursor: pointer;
  }
`

export default StyledMagazineCardCarousel
