import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Parser } from 'xml2js'
import find from 'lodash/find'
import ProductAd from './ProductAd'
import { validatePowerAd, validateDNAAd } from '@otavamedia/om-component-library/lib/lib/utils'

import './ProductAds.scss'

export default class ProductAds extends Component {
  constructor (props) {
    super(props)
    this.state = {
      adData: [],
      relatedData: [],
    }
  }

  static propTypes = {
    ads: PropTypes.array,
    related: PropTypes.array,
  }

  getStoreApiUrl (store) {
    switch (store) {
    case 'power':
      return 'https://www.power.fi/Umbraco/Api/Product/GetProductsByIds?ids='
    case 'dna':
      return 'https://kampanja3.dna.fi/hybris-api/adfeed?'
    }
  }

  validDataForAd = (data, store) => {
    switch (store) {
    case 'power':
      return validatePowerAd(data)
    case 'dna':
      return validateDNAAd(data)
    }
  }

  parsePowerData = (data, store) => {
    return {
      id: data.ProductId,
      title: data.Title,
      link: `https://www.power.fi${data.Url}`,
      price: data.Price,
      previousPrice: data.PreviousPrice,
      customLabel: data.ShortDescription,
      store,
    }
  }

  parseDNAData = (product, store) => {
    return {
      id: product.product_id,
      title: product.product_name,
      link: 'https://track.adform.net/C/?bn=27493885;cpdir=' + encodeURIComponent(product.product_deeplink) +
      '?utm_source=Rakennusmaailma.fi&utm_medium=cpc&utm_campaign=handset_jatkuva_suora&utm_content=tuotelistaus',
      price: parseFloat(product.product_price.replace(',', '.').replace(/[^0-9.]/, '')),
      customLabel: product.product_summary,
      store,
    }
  }

  getDNAAdData = async (data, store, productId) => {
    const parser = new Parser({ explicitArray: false })
    const jsonObj = await new Promise((resolve, reject) => {
      parser.parseString(data, (err, result) => {
        if (err) {
          reject(err)
        } else {
          resolve(result)
        }
      })
    })

    if (!jsonObj || !jsonObj.products || !jsonObj.products.product) {
      return null
    }
    const product = find(jsonObj.products.product, { product_id: productId })
    if (!product) {
      return null
    }
    return product
  }

  getAdData = (data, store, productId) => {
    switch (store) {
    case 'power':
      return data[0]
    case 'dna':
      return this.getDNAAdData(data, store, productId)
    }
  }

  parseAdData = (data, store) => {
    switch (store) {
    case 'power':
      return this.parsePowerData(data, store)
    case 'dna':
      return this.parseDNAData(data, store)
    }
  }

  async getDataForAds (ads) {
    return Promise.all(ads.map(async (item) => {
      const response = await axios.get(`${this.getStoreApiUrl(item.store)}${item.productID}`)

      const adData = await this.getAdData(response.data, item.store, item.productID)

      if (this.validDataForAd(adData, item.store)) {
        return this.parseAdData(adData, item.store)
      }
      return null
    }))
  }

  sort (list) {
    return list.sort((a, b) => a.price === b.price ? Math.random() - 0.5 : a.price - b.price)
  }

  async componentDidMount () {
    const { ads, related } = this.props
    let adDataList = []
    let relatedDataList = []

    if (ads) {
      adDataList = (await this.getDataForAds(ads.filter((x) => x && x.productID))).filter((x) => x)
    }
    if (related) {
      relatedDataList = (await this.getDataForAds(related.filter((x) => x && x.productID))).filter((x) => x)
    }

    this.setState({
      adData: this.sort(adDataList),
      relatedData: this.sort(relatedDataList),
    })
  }

  render () {
    const { adData, relatedData } = this.state

    if (!adData.length && !relatedData.length) {
      return null
    }

    return (
      <div styleName="root">
        {adData.length
          ? <Fragment>
            <span styleName="note">Saatavilla nettikaupasta</span>
            <div>
              {adData.map(ad => (
                <ProductAd key={`${ad.store}-${ad.id}`} ad={ad}/>
              ))}
            </div>
          </Fragment>
          : null}
        {relatedData.length
          ? <Fragment>
            <span styleName="note">Vastaavia tuotteita nettikaupasta</span>
            <div>
              {relatedData.map(ad => (
                <ProductAd key={`${ad.store}-${ad.id}`} ad={ad}/>
              ))}
            </div>
          </Fragment>
          : null}
      </div>
    )
  }
}
